import React from 'react';
import PropTypes from 'prop-types';

import FormControlMoney from 'components/Lib/BootstrapReduxForm/FormControlMoney';

export const OpenAmount = (props) => {
	const {
		title,
		subTitle,
	} = props;

	return (
		<div className="open-amount mt-5">
			<h5>{ title }</h5>
			<p>{ subTitle }</p>

			<FormControlMoney
				name="openAmountInCents"
				label="Amount"
				controlColProps={{ sm: 6, xl: 5 }}
			/>

		</div>
	);
}

OpenAmount.propTypes = {
	title: PropTypes.string,
	subTitle: PropTypes.string,
}

OpenAmount.defaultProps = {
	title: "Give towards future requests",
	subTitle: "We'll apply the amount of your choice towards future request fulfillment.",
}

export default OpenAmount;
