import React from 'react';
import PropTypes from 'prop-types';

export const SectionTitle = (props) => {
	const {
		title,
		className,
	} = props;

	return (
		<h3 className={`header-rule ${className}`}>
			{ title }
		</h3>
	);
}

SectionTitle.propTypes = {
	title: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element,
	]).isRequired,
}

export default SectionTitle;
