import axios from 'axios';

import { getWhitelabelApiUrl } from 'utils/api';

const END_POINT = 'donationRequests';
const ACTION_ROOT = 'donationRequests';

const SET_SEEKER_REQUESTS_LIST = `${ACTION_ROOT}/SET_SEEKER_REQUESTS_LIST`;
const CLEAR_SEEKER_REQUESTS = `${ACTION_ROOT}/CLEAR_SEEKER_REQUESTS`;
const SET_SEEKER_REQUESTS_LOADING = `${ACTION_ROOT}/SET_SEEKER_REQUESTS_LOADING`;

const initialState = {
	list: [],
	loading: false,
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_SEEKER_REQUESTS_LIST:
			return {
				...state,
				list: action.payload,
				loading: false,
			};
		case CLEAR_SEEKER_REQUESTS:
			return { ...initialState };
		case SET_SEEKER_REQUESTS_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
}

export function getDonationRequests({
	whitelabelKey,
	programCategory,
	zipcode,
} = {}) {
	return function(dispatch) {
		(dispatch)(setDonationRequestsLoading(true));

		axios.get( getWhitelabelApiUrl(whitelabelKey, END_POINT) )
		.then( (response) => {
			const filteredRequests = response.data.filter( (request) => {
				if ( programCategory && request.category !== programCategory ) {
					return false;
				}

				// TODO: SMARTER ZIPCODE LOGIC
				if ( zipcode && request.location !== zipcode ) {
					return false;
				}

				return true;
			});

			dispatch({
				type: SET_SEEKER_REQUESTS_LIST,
				payload: filteredRequests,
			});
		})
		.catch( (error) => {
			(dispatch)(setDonationRequestsLoading(false));
			console.error(error);
		});
	}
}

export function clearDonationRequests() {
	return function(dispatch) {
		dispatch({
			type: CLEAR_SEEKER_REQUESTS,
		});
	}
}

export function setDonationRequestsLoading(payload) {
	return function(dispatch) {
		dispatch({
			payload,
			type: SET_SEEKER_REQUESTS_LOADING,
		});
	}
}
