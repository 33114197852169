const ACTION_ROOT = 'stripe';

const SET_STRIPE = `${ACTION_ROOT}/SET_STRIPE`;
const SET_PUBLISHABLE_KEY = `${ACTION_ROOT}/SET_PUBLISHABLE_KEY`;
const SET_CARD_ELEMENT = `${ACTION_ROOT}/SET_CARD_ELEMENT`;
const CLEAR_ALL_ELEMENTS = `${ACTION_ROOT}/CLEAR_ALL_ELEMENTS`;

const initialState = {
	stripe: null,
	publishableKey: null,
	cardElement: {},
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_STRIPE:
			return {
				...state,
				stripe: action.payload,
			}
		case SET_PUBLISHABLE_KEY:
			return {
				...state,
				publishableKey: action.payload,
			}
		case SET_CARD_ELEMENT:
			return {
				...state,
				cardElement: action.payload,
			}
		case CLEAR_ALL_ELEMENTS:
			return {
				...state,
				cardElement: {},
			}
		default:
			return state;
	}
}

export function setStripe( stripeFx, publishableKey ) {
	return function(dispatch) {
		dispatch({
			type: SET_STRIPE,
			payload: stripeFx(publishableKey),
		});
	}
}

export function setPublishableKey( publishableKey ) {
	return function(dispatch) {
		dispatch({
			type: SET_PUBLISHABLE_KEY,
			payload: publishableKey,
		});
	}
}

export function setCardElement( element ) {
	return function(dispatch) {
		dispatch({
			type: SET_CARD_ELEMENT,
			payload: element,
		});
	}
}

export function clearAllElements() {
	return function(dispatch) {
		dispatch({
			type: CLEAR_ALL_ELEMENTS,
		});
	}
}
