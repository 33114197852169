export default function(formProps, ownProps) {
	let errors = {};

	const required = [
		"firstName",
		"lastName",
		"emailAddress",
		"addressZip",
	];

	// REQUIRED FIELDS
	required.forEach( (fieldName) => {
		if( ! formProps[fieldName] ) {
			errors[fieldName] = "A value is required";
		}
	});

	// AT LEAST ONE REQUEST SELECTED OR AN OPEN AMOUNT
	if ( ! formProps.seekerRequestKeys || ! formProps.seekerRequestKeys.length ) {
		if ( ! formProps.openAmountInCents ) {
			errors.openAmountInCents = "A value is required"
		}
		else if ( isNaN(parseFloat(formProps.openAmountInCents)) || !isFinite(formProps.openAmountInCents) || formProps.openAmountInCents <= 0 ) {
			errors.openAmountInCents = "Invalid amount"
		}
	}

	// EMAIL FORMAT
	if ( formProps.emailAddress && ! /[^\s@]+@[^\s@]+\.[^\s@]+/.test( formProps.emailAddress ) ) {
		errors.emailAddress = "Invalid email address";
	}

	return errors;
}
