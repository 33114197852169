import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import BootstrapForm from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import * as actions from 'redux/modules/mailingListSignup';

import FormControlInput from 'components/Lib/BootstrapReduxForm/FormControlInput';

export const FORM_NAME = 'mailing-list-signup-form';

export class MailingListSignup extends Component {
	constructor(props) {
		super(props);

		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}

	handleFormSubmit(data) {
		const {
			createSignup,
		} = this.props;

		createSignup(data);
	}

	render() {
		const {
			handleFormSubmit,
			props: {
				handleSubmit,
				waiting,
				error,
				response,
				submitFailed,
				invalid,
			},
		} = this;

		return (
			<div className="mailing-list-signup">
				{ ! response.success &&
					<BootstrapForm onSubmit={ handleSubmit( handleFormSubmit ) } >
						<div className="d-flex align-items-end">
							<div>
								<div className="d-flex flex-row align-items-end">
									<FormControlInput
										name="address"
										label="Join our mailing list:"
										placeholder="Email Address"
										horizontal={ false }
									/>
									<div className="form-group ml-1">
										<Button type="submit">Submit</Button>
										{ submitFailed && invalid &&
											<BootstrapForm.Control.Feedback type="invalid">
												&nbsp;
											</BootstrapForm.Control.Feedback>
										}
									</div>
								</div>

								{ error &&
									<BootstrapForm.Control.Feedback type="invalid" className="submit-error">
										There was an error.
									</BootstrapForm.Control.Feedback>
								}
							</div>

							{ waiting &&
								<div className="loading-icon-wrapper">
									<i className="loading-icon fas fa-spinner fa-spin fa-2x ml-2" />
								</div>
							}
						</div>
					</BootstrapForm>
				}

				{ response.success &&
					<div className="success-message">
						<i className="fa fa-check" />
						You have joined our mailing list!
					</div>
				}
			</div>
		);
	}
}

function validate(formProps) {
	let errors = {};

	const required = [
		"address",
	];

	// REQUIRED FIELDS
	required.forEach( (fieldName) => {
		if( ! formProps[fieldName] ) {
			errors[fieldName] = "A value is required";
		}
	});

	// EMAIL FORMAT
	if ( formProps.address && ! /[^\s@]+@[^\s@]+\.[^\s@]+/.test( formProps.address ) ) {
		errors.address = "Invalid email address";
	}

	return errors;
}

MailingListSignup = reduxForm({
	validate,
	form: FORM_NAME,
})(MailingListSignup);

function mapStateToProps(state) {
	return {
		waiting: state.mailingListSignup.waiting,
		response: state.mailingListSignup.response,
		error: state.mailingListSignup.error,
	}
}

MailingListSignup = connect(mapStateToProps, actions)(MailingListSignup);

export default MailingListSignup;
