import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-bootstrap/Carousel';

const ThankYouCarousel = (props) => {
	const {
		quotes,
	} = props;

	return (
		<div className="thank-you-carousel">
			<Carousel pauseOnHover={ false }>
				{ quotes.map( (item) => {
					const {
						message,
						location,
					} = item;

					return (
						<Carousel.Item key={message}>
							<div className="background" />
							<Carousel.Caption>
								<h3 className="message">{ message }</h3>
								<h4 className="location">&ndash; {location }</h4>
							</Carousel.Caption>
						</Carousel.Item>
					)
				})}
			</Carousel>
		</div>
	);
}

ThankYouCarousel.propTypes = {
	quotes: PropTypes.arrayOf(PropTypes.shape({
		message: PropTypes.string,
		location: PropTypes.string,
	})).isRequired,
}

export default ThankYouCarousel;
