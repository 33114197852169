export const ROUTE_PROGRAMS = 'programs';
export const ROUTE_THANK_YOU = 'thank-you';
export const ROUTE_TERMS_OF_SERVICE = 'terms-of-service';

export function getPathHome() {
	return "/";
}

export function getPathProgram(slug) {
	return `/${ROUTE_PROGRAMS}/${slug}`
}

export function getPathThankYou() {
	return `/${ROUTE_THANK_YOU}`;
}

export function getPathTermsOfService() {
	return `/${ROUTE_TERMS_OF_SERVICE}`;
}
