import React, { Component } from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
	CardElement,
} from 'react-stripe-elements';

import * as actions from 'redux/modules/stripe';

import Label from 'components/Lib/BootstrapReduxForm/FormGroup/Label';

import { FORM_NAME } from '../Form';

const STRIPE_STYLES = {
	base: {
		fontSize: "16px",
		fontFamily: "'Lato', sans-serif",
	},
	invalid: {
		color: "#D00000",
	}
}

export class StripeElements extends Component {
	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
	}

	get cardElementError() {
		const {
			cardElementState,
			form,
		} = this.props;

		if (cardElementState && cardElementState.error) {
			return true;
		}

		if (form.submitFailed && ! cardElementState.complete ) {
			return true;
		}

		return false;
	}

	get cardElementWrapperClassName() {
		const {
			cardElementError,
		} = this;

		let className = "form-control ";

		if (cardElementError) {
			className += "is-invalid ";
		}

		return className;
	}

	get cardElementErrorMessage() {
		const {
			cardElementState,
			form,
		} = this.props;

		if ( cardElementState && cardElementState.error && cardElementState.error.message ) {
			return cardElementState.error.message;
		}

		if ( form.submitFailed && ! cardElementState.complete ) {
			return "All values are required."
		}

		return null;
	}

	componentWillMount() {
		const {
			clearAllElements,
		} = this.props;

		clearAllElements();
	}

	handleChange(cardElement) {
		const {
			setCardElement,
		} = this.props;

		setCardElement( cardElement );
	}

	render() {
		const {
			cardElementWrapperClassName,
			handleChange,
		} = this;

		return (
			<Form.Group as={ Row }>
				<Label label="Card" horizontal />
				<Col sm={9}>
					<div className={ cardElementWrapperClassName }>
						<CardElement
							hidePostalCode
							onChange={ handleChange }
							style={ STRIPE_STYLES }
						/>
					</div>
					{ this.renderError() }
				</Col>
			</Form.Group>
		);
	}

	renderError() {
		const {
			cardElementErrorMessage,
		} = this;

		if ( ! cardElementErrorMessage ) {
			return null;
		}

		return (
			<div className="invalid-feedback">
				{ cardElementErrorMessage }
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		cardElementState: state.stripe.cardElement,
		form: state.form[FORM_NAME],
	}
}

StripeElements = connect(mapStateToProps, actions)(StripeElements);

export default StripeElements;
