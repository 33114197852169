import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';

export const Error = (props) => {
	const {
		message,
	} = props;

	return (
		<Alert variant="danger">
			{ message }
		</Alert>
	)
}

Error.propTypes = {
	message: PropTypes.string.isRequired,
}

export default Error;
