import React from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import { showError } from 'utils';

export const Feedback = (props) => {
	const {
		meta,
		meta: {
			error,
		},
	} = props;

	if ( ! showError(meta) ) {
		return null;
	}

	return (
		<Form.Control.Feedback type="invalid">
			{ error }
		</Form.Control.Feedback>
	);
}

Feedback.propTypes = {
	/** meta (usually from ReduxForm.Field) to determine error status and message **/
	meta: PropTypes.shape({
		touched: PropTypes.bool,
		error: PropTypes.string,
	}).isRequired,
}

export default Feedback
