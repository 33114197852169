import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import { Flipper, Flipped } from 'react-flip-toolkit';
import Form from 'react-bootstrap/Form';

import RequestCard from 'components/Lib/RequestCard';
import Feedback from '../Feedback';

const MIN_SLIDER_STEPS = 0;
const MAX_SLIDER_STEPS = 10;

export class Control extends Component {
	constructor(props) {
		super(props);

		this.handleSliderChange = this.handleSliderChange.bind(this);
	}

	get maxRequests() {
		const {
			requests,
		} = this.props;

		return (requests.length < MAX_SLIDER_STEPS) ? requests.length : MAX_SLIDER_STEPS;
	}

	get sliderMarks() {
		const {
			maxRequests,
		} = this;

		let marks = {};

		for( let count = MIN_SLIDER_STEPS; count <= maxRequests; count++ ) {
			if (count === 0) {
				marks[0] = "$";
			}
			else {
				marks[count] = count;
			}
		}

		return marks;
	}

	get valueArray() {
		const {
			value,
		} = this.props.input;

		if ( ! value || ! Array.isArray(value) ) {
			return [];
		}

		return [...value];
	}

	get selectedRequests() {
		const {
			valueArray,
			props: {
				requests,
			}
		} = this;

		const selected = valueArray.map( (requestKey) => requests.find( (request) => request.key === requestKey) );
		const safeSelected = selected.filter( (request) => request );
		return safeSelected.reverse();
	}

	handleSliderChange(count) {
		const {
			input,
			requests,
		} = this.props;

		const sliceCount = (count <= requests.length) ? count : requests.length;
		const workingRequests = [...requests];

		const newValue = workingRequests.slice(0, sliceCount).map(request => request.key);

		input.onChange( newValue );
		input.onBlur();
	}

	render() {
		const {
			maxRequests,
			sliderMarks,
			selectedRequests,
			handleSliderChange,
			props: {
				input: {
					value,
				},
			},
		} = this;

		return (
			<div className="form-control-request-slider">
				<div className="form-group-slider d-flex">
					<Form.Label>
						Fulfill
					</Form.Label>
					<div className="slider-wrapper flex-grow-1">
						<Slider
							min={MIN_SLIDER_STEPS}
							max={maxRequests}
							value={ selectedRequests.length }
							marks={ sliderMarks }
							step={ null }
							onChange={ handleSliderChange }
						/>
					</div>
				</div>

				<div className="requests-list-wrapper">
					{ value &&
						<Flipper flipKey={selectedRequests.map(req => req.key).join("")}>
							<ul className="list-unstyled">
								{ selectedRequests.map( (seekerRequest) => {
									const {
										key,
									} = seekerRequest;

									return (
										<Flipped key={key} flipId={key}>
											<li>
												<RequestCard
													{...seekerRequest}
													seekerRequestKey={ seekerRequest.key }
													active
												/>
											</li>
										</Flipped>
									);
								}) }
							</ul>
						</Flipper>
					}
				</div>

				<Feedback {...this.props} />
			</div>
		);
	}
}

Control.propTypes = {
	/** (usually from ReduxForm.Field) */
	input: PropTypes.shape({
		value: PropTypes.oneOfType([
			PropTypes.array,
			PropTypes.string,
		]),
		onBlur: PropTypes.func.isRequired,
		onChange: PropTypes.func.isRequired,
	}).isRequired,

	/** list of requests to choose from */
	requests: PropTypes.arrayOf(PropTypes.shape({
		key: PropTypes.string.isRequired,
	})).isRequired,

}
export default Control;
