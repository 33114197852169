import React from 'react';

const DefaultContent = () => {
	return (
		<div>
			<h2>How It Works</h2>
			<p>Give To a Neighbor is partnering with <a href="http://auntbertha.com/">Aunt Bertha</a>, the leading social care search and referrals platform in the United States, to help fill needs that can’t immediately be filled by the amazing network of Community Based Organizations (CBOs).</p>
			<p>Millions of users search Aunt Bertha’s Social Care Network for free and reduced cost social services programs. In many cases, they find help. But in some cases, there just isn’t enough supply to help them.</p>
			<p>That’s where the idea for Give To a Neighbor was born. Partnering with Aunt Bertha, we created an additional way for people to get help.  A person asks for what they need most on Aunt Bertha’s platform, and we connect them with you — their neighbor — to help meet those needs.</p>
			<p>Each request is made on Aunt Bertha and then shows up over here. Once you fulfill a request, we’ll send your neighbor their requested item or service and we’ll let you know when they get it.</p>
			<p>These requests are anonymous. We believe in protecting the identity of people in need asking for help so we don’t share identifiable information. This means we get to test out a concept: will a neighbor give to a neighbor anonymously?  We’re believers.</p>
			<p>If you want to learn more, send us a note at <a href="info@givetoaneigbor.org">info@givetoaneigbor.org</a>.</p>
		</div>
	)
}

export default DefaultContent;
