import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';

import ZipcodeForm from './ZipcodeForm';

export class LocationSetting extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showZipcode: false,
		};

		this.handleZipcodeSubmit = this.handleZipcodeSubmit.bind(this);
		this.handleSetShowZipcode = this.handleSetShowZipcode.bind(this);
		this.handleSetHideZipcode = this.handleSetHideZipcode.bind(this);
	}

	handleZipcodeSubmit(data) {
		console.log(data);
	}

	handleSetShowZipcode() {
		this.setState({ showZipcode: true });
	}

	handleSetHideZipcode() {
		this.setState({ showZipcode: false });
	}

	render() {
		const {
			handleZipcodeSubmit,
			state: {
				showZipcode,
			},
		} = this;

		return (
			<div className="location-setting">
				{ showZipcode &&
					<ZipcodeForm onSubmit={ handleZipcodeSubmit } />
				}

				<Dropdown className="location-setting-dropdown">
					<Dropdown.Toggle variant="link" id="location-setting-toggle">
						{ ! showZipcode &&
							"All Locations"
						}
					</Dropdown.Toggle>
					<Dropdown.Menu>
						{ this.renderDropdownItems() }
					</Dropdown.Menu>
				</Dropdown>
			</div>
		);
	}

	renderToggleContent() {
		const {
			handleZipcodeSubmit,
			state: {
				showZipcode,
			},
		} = this;

		if ( ! showZipcode ) {
			return "All Locations";
		}

		return (
			<ZipcodeForm
				onSubmit={ handleZipcodeSubmit }
			/>
		);
	}

	renderDropdownItems() {
		const {
			handleSetShowZipcode,
			handleSetHideZipcode,
			state: {
				showZipcode,
			},
		} = this;

		if ( ! showZipcode ) {
			return (
				<Dropdown.Item>
					<Button onClick={handleSetShowZipcode} variant="link">
						Search by Zipcode
					</Button>
				</Dropdown.Item>
			);
		}

		return (
			<Dropdown.Item>
				<Button onClick={handleSetHideZipcode} variant="link">
					All Locations
				</Button>
			</Dropdown.Item>
		);
	}
}

export default LocationSetting;
