import React from 'react';
import BootstrapContainer from 'react-bootstrap/Container';

export const StandardContainer = (props) => {
	const {
		children,
		className,
	} = props;

	const containerClassName = `container-lg ${className}`;

	return(
		<BootstrapContainer fluid className={ containerClassName }>
			{ children }
		</BootstrapContainer>
	);
}

export default StandardContainer;
