// POLYFILL
import 'core-js/features/object/assign';
import 'core-js/features/array/find';
import 'core-js/features/promise';
import 'core-js/features/url-search-params';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Route } from "react-router-dom";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore, { history } from './configureStore'
import withGoogleAnalytics from 'components/HigherOrder/withGoogleAnalytics';

import { getWhitelabel } from 'redux/modules/whitelabel';
import { setPublishableKey } from 'redux/modules/stripe';
import { setPrograms } from 'redux/modules/programs';

const store = configureStore();

requestWhiteLabel();

function requestWhiteLabel() {
	const urlParams = new URLSearchParams(window.location.search);
	const location =  urlParams.has('whitelabel') ?  urlParams.get('whitelabel') : window.location.host;

	( getWhitelabel(
		location,
		({ data }) =>  {
			if ( ! data || ! data.key ) {
				console.error("whitelabel not found");
				return;
			}

			const {
				name,
				stripePublishableKey,
				programs,
				givingSiteDesign,
			} = data;

			if ( ! stripePublishableKey ) {
				console.error("stripe key not found");
				return;
			}

			setPublishableKey(stripePublishableKey)(store.dispatch);
			setPrograms(programs)(store.dispatch);

			// SET HEADER TITLE AND FAVICON
			document.title = name;
			if (givingSiteDesign && givingSiteDesign.favicon) {
				const favicon = document.getElementById("favicon");
				favicon.href = givingSiteDesign.favicon;
			}

			initApp();
		},
	))(store.dispatch);
}

function initApp() {
	ReactDOM.render(
		<Provider store={ store }>
			<ConnectedRouter history={history}>
				<Route component={withGoogleAnalytics(App)} />
			</ConnectedRouter>
		</Provider>,
		document.getElementById('root')
	);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
