import React, { Component } from 'react';
import { Route, withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import 'sass/App.scss';

import {
	getPathHome,
	getPathProgram,
	getPathThankYou,
	getPathTermsOfService,
} from 'utils/routes';

import { setStripe } from 'redux/modules/stripe';

import Header from 'components/Header';
import Footer from 'components/Footer';
import FulfilledRequestsRibbon from 'components/FulfilledRequestsRibbon';

import ProgramGiveGift from 'components/Pages/ProgramGiveGift';
import ThankYou from 'components/Pages/ThankYou';
import TermsOfService from 'components/Pages/TermsOfService';

const actions = {
	setStripe,
};

class App extends Component {
	componentDidMount () {
		const {
			setStripe,
			stripePublishableKey,
		} = this.props;

		if ( ! window.Stripe ) {
			const stripeJs = document.createElement('script');
			stripeJs.src = 'https://js.stripe.com/v3/';
			stripeJs.async = true;

			stripeJs.onload = () => {
				setTimeout(() => {
					setStripe( window.Stripe, stripePublishableKey );
				}, 500);
			}

			document.body && document.body.appendChild(stripeJs);
		}
	}

	render() {
		const {
			programs,
			activeProgram,
		} = this.props;

		return (
			<div className="App d-flex flex-column justify-content-between">
				<div className="top">
					<Header
						programs={ programs }
						activeCategory={ activeProgram.category }
					/>

					<div className="page-content">
						<Route path={ getPathHome() } exact component={ ProgramGiveGift } />
						<Route path={ getPathProgram(':programSlug') } exact component={ ProgramGiveGift } />
						<Route path={ getPathThankYou() } component={ ThankYou } />
						<Route path={ getPathTermsOfService() } component={ TermsOfService } />
					</div>
				</div>

				<div className="bottom">
					<FulfilledRequestsRibbon />
					<Footer />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		programs: state.programs.list,
		activeProgram: state.programs.active,
		stripePublishableKey: state.stripe.publishableKey,
	}
}

App = connect(mapStateToProps, actions)(App);
App = withRouter(App);

export default App;
