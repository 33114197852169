export function getValueFromKey( data, fieldKey ) {
	if ( !fieldKey || !data ) {
		return data;
	}

	let value = data;
	const keys = fieldKey.split(".");

	for( let i=0; i<keys.length; i++ ) {
		const key = keys[i];
		if( typeof value[key] === "undefined" ) {
			return null;
		}

		value = value[key];
	}

	return value;
}

export function formatNumber(amount, decimals=2) {
	if ( ! amount ) {
		return amount;
	}

	return (amount).toLocaleString('en', {minimumFractionDigits: decimals, maximumFractionDigits: decimals});
}

export function formatMoney(amount, decimals=2) {
	if ( ! amount ) {
		return amount;
	}

	return '$' + formatNumber( amount, decimals );
}

export function formatPenniesToMoney(pennies, decimals=2) {
	if ( ! pennies ) {
		return pennies;
	}

	return formatMoney( pennies / 100, decimals);
}

export function showError({ touched, invalid }) {
	return ( touched && invalid );
}
