import React from 'react';
import { Link } from 'react-router-dom';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import StandardContainer from 'components/Lib/StandardContainer';

export const ThankYou = (props) => {
	return (
		<div className="page-thank-you content-wrapper">
			<StandardContainer>
				<Row>
					<Col xs={12}>
						<div className="thank-you-content">
							<h1 className="text-center title mb-4 mt-3">Thank You!</h1>
							<p>Thank you so much for helping out your neighbor in need. As soon as they get the item you’ve given, we’ll let you know.</p>
							<p>If you’d like to help another neighbor, choose a program from the header or the "See More Requests" link below.</p>

							<p className="text-center mt-5">
								<Link to="/">See More Requests</Link>
							</p>
						</div>
					</Col>
				</Row>
			</StandardContainer>
		</div>
	)
}

export default ThankYou;
