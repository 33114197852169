import React from 'react';
import PropTypes from 'prop-types';

import StandardContainer from 'components/Lib/StandardContainer';

import ProgramSelection from './ProgramSelection';
import LocationSetting from './LocationSetting';

export const SecondaryNav = (props) => {
	const {
		programs,
	} = props;

	const programsDisplay = () => {
		if (! programs.length ) {
			return null;
		}

		if ( programs.length === 1 ) {
			return (
				<h1 className="program-title">
					{ programs[0].title }
				</h1>
			);
		}

		return <ProgramSelection {...props} />;
	}

	return (
		<div className="secondary-nav">
			<StandardContainer className="h-100">
				<div className="d-flex flex-row justify-content-between align-items-center h-100">
					{ programsDisplay() }

					<div style={{ display: "none "}}>
						<LocationSetting />
					</div>
				</div>
			</StandardContainer>
		</div>
	);
}

SecondaryNav.propTypes = {
	...ProgramSelection.propTypes,
	programs: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string,
	})),
}

export default SecondaryNav;
