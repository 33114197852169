import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

import { showError } from 'utils';

import {
	getCentsFromDollars,
	getDollarsFromCents,
} from './utils';

import Feedback from '../Feedback';

export class Control extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: ''
		};

		this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {
		const {
			input,
		} = this.props;

		let { value } = event.target;

		input.onChange( getCentsFromDollars(value) );
		input.onBlur();
	}

	render() {
		const {
			handleChange,
			props: {
				input: {
					value,
				},
				controlProps,
				controlType,
				meta,
				placeholder,
			},
		} = this;

		return (
			<InputGroup>
				<InputGroup.Prepend>
					<InputGroup.Text><i className="fas fa-dollar-sign" /></InputGroup.Text>
				</InputGroup.Prepend>
				<Form.Control
					{...controlProps}
					value={ getDollarsFromCents(value) }
					type={ controlType }
					onChange={ handleChange }
					isInvalid={ showError(meta) }
					placeholder={ placeholder }
				/>
				<Feedback meta={ meta } />
			</InputGroup>
		);
	}
}

Control.propTypes = {
	/** props (usually from ReduxForm.Field) spread to control element */
	input: PropTypes.object.isRequired,

	/** to spread to react-bootstrap Form.Control */
	controlProps: PropTypes.object,

	/** input type of control */
	controlType: PropTypes.string,

	/** placeholder text for control */
	placeholder: PropTypes.string,

	/** meta (usually from ReduxForm.Field) to determine error status and message **/
	meta: PropTypes.shape({
		touched: PropTypes.bool,
		error: PropTypes.string,
	}).isRequired,
}

Control.defaultProps = {
	controlType: "number",
}

export default Control;
