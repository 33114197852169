import React from 'react';
import PropTypes from 'prop-types';

import Col from 'react-bootstrap/Col';

export const ControlWrapper = (props) => {
	const {
		horizontal,
		children,
		...restColProps
	} = props;

	if ( ! horizontal ) {
		return children;
	}

	return (
		<Col {...restColProps}>
			{ children }
		</Col>
	)
}

ControlWrapper.propTypes = {
	...Col.propTypes,

	/** render horizontal layout */
	horizontal: PropTypes.bool,
}

ControlWrapper.defaultProps = {
	sm: 9,
}

export default ControlWrapper;
