import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { withRouter } from 'react-router-dom';
import BootstrapForm from 'react-bootstrap/Form';

import {
	injectStripe,
} from 'react-stripe-elements';

import * as actions from 'redux/modules/donation';
import { getPathThankYou } from 'utils/routes';

import validate from './validate';

export const FORM_NAME = 'program-give-gift-form';

export class Form extends Component {
	render() {
		const {
			children,
			onSubmit,
			handleSubmit,  // from reduxForm
		} = this.props;

		return (
			<BootstrapForm onSubmit={ handleSubmit( onSubmit ) } >
				{ children }
			</BootstrapForm>
		);
	}
}

function handleSubmitSuccess(data, dispatch, props) {
	const {
		stripe,
		createDonation,
		setWaiting,
		setError,
		history,
		whitelabel: {
			key: whitelabelKey,
		}
	} = props;

	stripe.createToken({
		address_zip: data.zipcode,
		address_country: 'US',
	})
	.then((response) => {
		// console.log(JSON.stringify(response));
		// return;

		if ( response.error ) {
			// stripe throws onChange when error is returned
			// error messaging being handled in ./PaymentInformation/StripeElements onChange functionality
			setWaiting(false);
			return;
		}

		if ( ! response.token ) {
			setError("Stripe did not respond with a token.")
			setWaiting(false);
			console.error('no token', response);
		}

		const newData = {
			...data,
			stripeToken: response.token.id,
			domain: window.location.hostname,
		}

		createDonation(whitelabelKey, newData, (response)=> {
			if ( response.data.success ) {
				history.push( getPathThankYou() );
			}
		});
	})
	.catch( (error) => {
		setError("Stripe did not respond.")
		setWaiting(false);
		console.error(error);
	});
}


Form = reduxForm({
	validate,
	form: FORM_NAME,
	enableReinitialize: true,
	destroyOnUnmount: false,
	onSubmitSuccess: handleSubmitSuccess,
})(Form);

function mapStateToProps(state) {
	return {
		donationRequests: state.donationRequests.list,
		whitelabel: state.whitelabel.active,
	};
}

Form = connect(mapStateToProps, actions)(Form);
Form = withRouter(Form);

Form.propTypes = {
	onSubmit: PropTypes.func.isRequired,
}

Form = injectStripe(Form);

export default Form;
