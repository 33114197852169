import React from 'react';
import PropTypes from 'prop-types';

const TitleBar = (props) => {
	const {
		title,
	} = props;

	return (
		<div className="title-bar p-4">
			<h3 className="title">{ title }</h3>
		</div>
	);
}

TitleBar.propTypes = {
	title: PropTypes.string.isRequired,
};

export default TitleBar;
