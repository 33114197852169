import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from 'redux/modules/navigation';

import LogoNav from './LogoNav';
import HowItWorks from './HowItWorks';
import SecondaryNav from './SecondaryNav';

export class Header extends Component {
	constructor(props) {
		super(props);

		this.handleToggleHowItWorks = this.handleToggleHowItWorks.bind(this);
	}

	handleToggleHowItWorks() {
		const {
			showHowItWorks,
			setShowHowItWorks,
		} = this.props;

		setShowHowItWorks( ! showHowItWorks );
	}

	render() {
		const {
			handleToggleHowItWorks,
			props: {
				showHowItWorks,
				whitelabel,
			},
		} = this;

		return (
			<header>
				<LogoNav onClickHowItWorks={handleToggleHowItWorks} whitelabel={ whitelabel } />
				<HowItWorks show={showHowItWorks} whitelabel={ whitelabel } />
				<SecondaryNav {...this.props} />
			</header>
		);
	}
}

function mapStateToProps(state) {
	return {
		showHowItWorks: state.navigation.showHowItWorks,
		whitelabel: state.whitelabel.active,
	}
}

Header = connect(mapStateToProps, actions)(Header);

Header.propTypes = {
	...SecondaryNav.propTypes,
}

export default Header;
