import React from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Label from 'components/Lib/BootstrapReduxForm/FormGroup/Label';
import FormControlInput, { Control } from 'components/Lib/BootstrapReduxForm/FormControlInput';

export const Expiration = (props) => {
	return (
		<Form.Group as={ Row } className="form-group-name">
			<Label label="Name" horizontal />
			<Col sm={9}>
				<Row>
					<Col sm={6}>
						<FormControlInput
							name="firstName"
							label="First Name"
							placeholder="First"
							component={ Control }
						/>
					</Col>
					<Col sm={6}>
						<FormControlInput
							name="lastName"
							label="Last name"
							placeholder="Last"
							component={ Control }
						/>
					</Col>
				</Row>
			</Col>
		</Form.Group>
	);
}

export default Expiration;
