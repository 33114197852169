import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormControlRequestSlider from 'components/Lib/BootstrapReduxForm/FormControlRequestSlider';

class DonationRequests extends Component {
	render() {
		const {
			requests,
			loading,
			whitelabel: {
				givingSiteDesign,
			},
		} = this.props;

		if ( loading ) {
			return (
				<div className="text-center">
					<i className="fas fa-spinner fa-spin fa-2x" style={{ opacity: ".5" }} />
				</div>
			)
		}

		const showSlider = (requests.length) ? true : false;

		return (
			<div className="seeker-requests">
				{ ! requests.length &&
					<p><em>
						We hope to be able to help people with this need very soon.
						Check back here shortly
						{ givingSiteDesign && givingSiteDesign.showMailingListSignup &&
							" or join our mailing list below to receive an update"
						}
						!
					</em></p>
				}

				{ showSlider &&
					<FormControlRequestSlider
						name="seekerRequestKeys"
						requests={ requests }
					/>
				}
			</div>
		)
	}
}

function mapStateToProps(state) {
	return {
		whitelabel: state.whitelabel.active,
	}
}


DonationRequests.propTypes = {
	requests: PropTypes.arrayOf(PropTypes.shape({
		key: PropTypes.string.isRequired,
	})),
	loading: PropTypes.bool,
	whitelabel: PropTypes.shape({ // connect - mapStateToProps
		givingSiteDesign: PropTypes.shape({
			showMailingListSignup: PropTypes.bool,
		}),
	}).isRequired,
}

DonationRequests.defaultProps = {
	requests: [],
}

DonationRequests = connect(mapStateToProps)(DonationRequests);

export default DonationRequests;
