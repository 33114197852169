import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import animateScrollTo from 'animated-scroll-to';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';

import * as actions from 'redux/modules/navigation';

import { getPathTermsOfService } from 'utils/routes';

import StandardContainer from 'components/Lib/StandardContainer';

import MailingListSignup from './MailingListSignup';

class Footer extends Component {
	constructor(props) {
		super(props);

		this.handleHowItWorksClick = this.handleHowItWorksClick.bind(this);
	}

	handleHowItWorksClick() {
		const {
			setShowHowItWorks,
		} = this.props;

		setShowHowItWorks(true);
		animateScrollTo(0, 1000);
	}

	render() {
		const {
			handleHowItWorksClick,
			props: {
				whitelabel: {
					givingSiteDesign,
				},
			},
		} = this;

		return (
			<footer>
				<StandardContainer>
					<Row>
						<Col sm={6} className="text-center text-sm-left">
							{ givingSiteDesign && givingSiteDesign.showMailingListSignup &&
								<MailingListSignup />
							}
						</Col>
						<Col sm={6}>
							<Nav className="right-menu justify-content-sm-end justify-content-center">
								<Nav.Link onClick={ handleHowItWorksClick }>How It Works</Nav.Link>
								<Link className="nav-link" to={getPathTermsOfService()}>Terms of Service</Link>
							</Nav>
							<div className="copyright text-sm-right text-center">
								&copy; copyright { getCopyrightYear() }
							</div>
						</Col>
					</Row>
				</StandardContainer>
			</footer>
		);
	}
}

function getCopyrightYear() {
	const date = new Date();
	return date.getFullYear();
}

function mapStateToProps(state) {
	return {
		whitelabel: state.whitelabel.active,
	}
}

Footer.propTypes = {
	whitelabel: PropTypes.shape({
		givingSiteDesign: PropTypes.shape({
			showMailingListSignup: PropTypes.bool,
		}),
	}).isRequired,
}
Footer = connect(mapStateToProps, actions)(Footer);

export default Footer;
