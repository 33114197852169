import axios from 'axios';

import { getWhitelabelApiUrl } from 'utils/api';

const END_POINT = 'donations';
const ACTION_ROOT = 'donations';

const SET_DONATION = `${ACTION_ROOT}/SET_DONATION`;
const SET_WAITING = `${ACTION_ROOT}/SET_WAITING`;
const SET_ERROR = `${ACTION_ROOT}/SET_ERROR`;

const initialState = {
	donation: {},
	response: {},
	waiting: false,
	error: "",
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_DONATION:
			const {
				donation,
				response,
			} = action.payload;

			return {
				...state,
				donation,
				response,
				error: "",
			};
		case SET_WAITING:
			return {
				...state,
				waiting: action.payload,
			};
		case SET_ERROR:
			return {
				...state,
				error: action.payload,
			};
		default:
			return state;
	}
}

export function createDonation(whitelabelKey, data, success) {
	return function(dispatch) {
		(dispatch)(setWaiting(true));

		axios.post( getWhitelabelApiUrl(whitelabelKey, END_POINT), data )
		.then( (response) => {

			dispatch({
				type: SET_DONATION,
				payload: {
					donation: data,
					response: response.data,
				},
			});

			(dispatch)(setWaiting(false));

			if (success) {
				success(response);
			}
		})
		.catch( (error) => {
			let errorMessage = "There was an error trying to make this gift";

			if ( error.response && error.response.data && error.response.data.message ) {
				errorMessage = error.response.data.message;
			}

			(dispatch)(setError(errorMessage));
			(dispatch)(setWaiting(false));
		});
	}
}

export function setWaiting(value) {
	return function(dispatch) {
		dispatch({
			type: SET_WAITING,
			payload: value,
		});
	}
}

export function setError(message) {
	return function(dispatch) {
		dispatch({
			type: SET_ERROR,
			payload: message,
		});
	}
}
