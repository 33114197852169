import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import Form from 'react-bootstrap/Form';

import FormControlInput from 'components/Lib/BootstrapReduxForm/FormControlInput';

export const FORM_NAME = 'zipcode-location-filter';

export class ZipcodeForm extends Component {
	render() {
		const {
			onSubmit,
			handleSubmit,  // from reduxForm
		} = this.props;

		return (
			<Form className="zipcode-form" onSubmit={ handleSubmit( onSubmit ) } >
				<FormControlInput
					name="zipcode"
					placeholder="Zipcode"
					horizontal={false}
				/>
			</Form>
		);
	}
}

ZipcodeForm = reduxForm({
	form: FORM_NAME,
})(ZipcodeForm);

ZipcodeForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
}

export default ZipcodeForm;
