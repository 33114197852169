import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import Control from './Control';

export { Control };

/** returns ReduxForm.Field with defaults */
export const FormControlRequestButtons = (props) => {
	return (
		<Field { ...props }/>
	);
}

FormControlRequestButtons.propTypes = {
	/** Pass through model to value (ReduxForm.Field) */
	name: PropTypes.string.isRequired,  // Pass through model to value (field in redux-form 5)

	/** component to render, defaults to FormGroupLayout */
	component: PropTypes.func,
};

FormControlRequestButtons.defaultProps = {
	component: Control,
};

export default FormControlRequestButtons;
