import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { formatPenniesToMoney } from 'utils';

import FormControlRoundUp from 'components/Lib/BootstrapReduxForm/FormControlRoundUp';

import DonationRequestsTitle from './DonationRequestsTitle';
import DonationRequests from './DonationRequests';
import OpenAmount from './OpenAmount';
import ProgramTitle from './ProgramTitle';
import ProgramInfo from './ProgramInfo';
import PaymentInformation from './PaymentInformation';
import Error from './Error';
import SubmitButton from './SubmitButton';

export class LayoutMd extends Component {
	get showRoundUp() {
		const {
			afterRoundUpTotalInCents,
		} = this.props;

		return (afterRoundUpTotalInCents) ? true : false;
	}

	get showOpenAmount() {
		const {
			donationRequests,
			selectedSeekerRequestKeys,
			donationRequestsLoading,
		} = this.props;

		if ( donationRequestsLoading ) {
			return false;
		}

		if ( ! donationRequests.length ) {
			return true;
		}

		if ( ! selectedSeekerRequestKeys.length ) {
			return true;
		}

		return false;
	}

	get openAmountTitle() {
		const {
			donationRequests,
			selectedSeekerRequestKeys,
		} = this.props;

		if ( ! donationRequests.length ) {
			return OpenAmount.defaultProps.title;
		}

		if ( ! selectedSeekerRequestKeys.length ) {
			return "Give what you can";
		}

		return OpenAmount.defaultProps.title;
	}

	render() {
		const {
			showOpenAmount,
			openAmountTitle,
			showRoundUp,
			props: {
				activeProgram,
				donationRequests,
				donationRequestsLoading,
				paymentTotalCents,
				errorMessage,
				donationWaiting,
				afterRoundUpTotalInCents,
				multipleProgramsAvailable,
			},
		} = this;

		return (
			<React.Fragment>
				{/* XS, SM top layout */}
				<div className="d-block d-md-none">
					<Row>
						<Col xs={12}>
							{ multipleProgramsAvailable &&
								<ProgramTitle {...activeProgram} key="program-title" />
							}

							{ activeProgram.auntBerthaProgramIdentifier &&
								<ProgramInfo {...activeProgram} key="program-info" />
							}
						</Col>
					</Row>
				</div>

				{/* MD+ top layout */}
				<div className="d-none d-md-block">
					<Row className="align-items-end two-col-center-padding-md">
						<Col md={6}>
							<DonationRequestsTitle />
						</Col>
						<Col md={6}>
							{ multipleProgramsAvailable &&
								<ProgramTitle {...activeProgram} />
							}
							{ ! multipleProgramsAvailable &&
								<div className="title-wrapper header-rule" />
							}
						</Col>
					</Row>
				</div>

				{/* XS, SM seeker requests layout */}
				<Row className="d-block d-md-none seeker-requests-wrapper py-4">
					<Col xs={12}>
						<DonationRequestsTitle />

						<DonationRequests
							requests={ donationRequests }
							loading={ donationRequestsLoading }
						/>

						{ showOpenAmount &&
							<OpenAmount title={ openAmountTitle } />
						}
					</Col>
				</Row>

				<Row className="two-col-center-padding-md">
					<Col md={6}>
						<div className="d-none d-md-block">
							<DonationRequests
								requests={ donationRequests }
								loading={ donationRequestsLoading }
							/>

							{ showOpenAmount &&
								<OpenAmount title={ openAmountTitle } />
							}
						</div>
					</Col>
					<Col md={6}>
						{ activeProgram.auntBerthaProgramIdentifier &&
							<div>
								<div className="d-none d-md-block">
									<ProgramInfo {...activeProgram} />
								</div>
								<PaymentInformation />
							</div>
						}
					</Col>
				</Row>

				{ showRoundUp &&
					<Row className="two-col-center-padding-md">
						<Col md={6}>
							<FormControlRoundUp
								name="roundUp"
								afterRoundUpTotalInCents={ afterRoundUpTotalInCents }
							/>
						</Col>
					</Row>
				}

				<Row className="two-col-center-padding-md">
					<Col md={6}>
						<div className="header-rule" />
						<h2 className="text-sm-right">Total Gift: { formatPenniesToMoney(paymentTotalCents, 2) }</h2>
					</Col>
					<Col md={6}>
						<div className="header-rule" />
						{ errorMessage &&
							<Error message={ errorMessage } />
						}
						<Row>
							<Col sm={{ span:9, offset: 3 }}>
								<SubmitButton
									disabled={ donationWaiting }
									waiting={ donationWaiting }
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</React.Fragment>
		);
	}
}

LayoutMd.propTypes = {
	activeProgram: PropTypes.shape({
		key: PropTypes.string,
	}).isRequired,
	donationRequests: PropTypes.array,
	donationRequestsLoading: PropTypes.bool,
	selectedSeekerRequestKeys: PropTypes.array,
	errorMessage: PropTypes.string,
	donationWaiting: PropTypes.bool,
	afterRoundUpTotalInCents: PropTypes.number,
	paymentTotalCents: PropTypes.number,
	multipleProgramsAvailable: PropTypes.bool,
}

LayoutMd.defaultProps = {
	donationRequests: [],
	selectedSeekerRequestKeys: [],
}

export default LayoutMd;
