import React from 'react';
import PropTypes from 'prop-types';
import { LinkContainer } from 'react-router-bootstrap';

import Dropdown from 'react-bootstrap/Dropdown';

import SecondaryNavItem from './SecondaryNavItem';

export const LayoutXs = (props) => {
	const {
		activeCategory,
		programs,
	} = props;

	const activeProgram = programs.find( (program) => program.category === activeCategory );

	return (
		<div className="d-block d-md-none">
			<Dropdown>
				<Dropdown.Toggle variant="link" id="location-setting-toggle">
					<SecondaryNavItem
						label={ (activeProgram) ? activeProgram.label : "" }
						active
					/>
				</Dropdown.Toggle>
				<Dropdown.Menu>
					{ programs.map( (program) => {
						const {
							auntBerthaProgramIdentifier,
							label,
							linkTo,
						} = program;

						return (
							<LinkContainer key={ auntBerthaProgramIdentifier } to={ linkTo }>
								<Dropdown.Item>
									{ label }
								</Dropdown.Item>
							</LinkContainer>
						);
					}) }
				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
}

LayoutXs.propTypes = {
	programs: PropTypes.arrayOf(PropTypes.shape({
		auntBerthaProgramIdentifier: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		linkTo: PropTypes.string.isRequired,
	})),
	activeCategory: PropTypes.string,
}

LayoutXs.defaultProps = {
	programs: [],
}

export default LayoutXs;
