import React from 'react';
import PropTypes from 'prop-types';

export const ProgramInfo = (props) => {
	const {
		label,
		slug,
		description,
		icon,
	} = props;

	return (
		<div className={`program-info ${slug}`}>
			<div className="details d-flex flex-row">
				<div className="description flex-grow-1">
					{ description }
				</div>

				{ icon && icon.url &&
					<div className="icon align-self-center">
						<img alt={label} src={ icon.url } />
					</div>
				}
			</div>
		</div>
	);
}

ProgramInfo.propTypes = {
	label: PropTypes.string.isRequired,
	slug: PropTypes.string.isRequired,
	title: PropTypes.string,
	description: PropTypes.string,
	iconUrl: PropTypes.string,
}

export default ProgramInfo;
