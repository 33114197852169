import React, { Component } from 'react';
import PropTypes from 'prop-types';
import zipcodes from 'zipcodes';
import moment from 'moment';

import { formatPenniesToMoney, formatNumber } from 'utils';

export class RequestCard extends Component {
	get locationLabel() {
		const {
			neighborhood,
			zipcode,
			state,
		} = this.props;

		let label = "";

		if ( zipcode ) {
			const locationInfo = zipcodes.lookup(zipcode);

			if ( locationInfo ) {
				label = `${locationInfo.city}, ${locationInfo.state}`;
			}
			else {
				label = (state) ? state : zipcode;
			}
		}
		else {
			label = state;
		}

		if ( neighborhood ) {
			if ( label ) {
				label += ' - ';
			}

			label += neighborhood;
		}

		return label;
	}

	get dateLabel() {
		const {
			dateCreated,
		} = this.props;

		return `Requested ${moment(dateCreated).format("MMMM Do")}`;
	}

	get timeElapsedLabel() {
		const {
			dateCreated,
		} = this.props;

		const now = moment.utc();
		const created = moment.utc(dateCreated);

		const hours = now.diff( created, 'hours' );
		const minutes = now.diff( created, 'minutes' );
		const days = now.diff( created, 'days' );

		const minutesDesc = (minutes === 1) ? "minute" : "minutes";
		const hourDesc = (hours === 1) ? "hour" : "hours";

		const minutesLabel = `${minutes} ${minutesDesc}`;
		const hoursLabel = `${hours} ${hourDesc}`;

		if ( hours < 1 ) {
			return `Requested ${minutesLabel} ago`;
		}

		if ( hours < 24 ) {
			return `Requested ${hoursLabel} ago`;
		}

		if ( days < 2 ) {
			return `Requested yesterday`;
		}

		if ( days < 7 ) {
			return `Requested more than a day ago`;
		}

		return `Requested more than a week ago`;
	}

	get amountLabel() {
		const {
			priceCents,
		} = this.props;

		return formatPenniesToMoney(priceCents, 0);
	}

	get amountDollarsLabel() {
		const {
			priceCents,
		} = this.props;

		const dollars = Math.floor(priceCents / 100);
		return '$' + formatNumber(dollars, 0);
	}

	get amountCentsLabel() {
		const {
			priceCents,
		} = this.props;

		const cents = priceCents % 100;
		return (cents >= 10) ? cents.toString() : `0${cents}`;
	}

	render() {
		const {
			locationLabel,
			timeElapsedLabel,
			amountDollarsLabel,
			amountCentsLabel,
			props: {
				details,
				active,
			},
		} = this;

		return (
			<ClickWrapper {...this.props}>
				<div className={`request-card ${ (active) ? 'active' : '' }`}>
					<div className="d-flex flex-row">
						<div className="details align-self-center">
							<div className="title">{ details }</div>
							<div className="d-flex flex-row justify-content-between">
								<div className="location">{ locationLabel }</div>
								<div className="date text-right">{ timeElapsedLabel }</div>
							</div>
						</div>

						<div className="amount align-self-center text-right">
							<span className="dollars">
								{ amountDollarsLabel }
							</span>
							<span className="cents">
								{ amountCentsLabel }
							</span>
						</div>

						<div className="status align-self-center">
							{ active &&
								<i className="fas fa-check" />
							}
						</div>
					</div>
				</div>
			</ClickWrapper>
		);
	}
}

const ClickWrapper = (props) => {
	const {
		seekerRequestKey,
		onClick,
		children,
	} = props;

	if ( ! onClick ) {
		return children;
	}

	return (
		<div onClick={ () => onClick(seekerRequestKey) } style={{ cursor: "pointer" }}>
			{ children }
		</div>
	);
}

RequestCard.propTypes = {
	seekerRequestKey: PropTypes.string.isRequired,
	dateCreated: PropTypes.string.isRequired,
	details: PropTypes.string,
	priceCents: PropTypes.number,
	location: PropTypes.string,
	active: PropTypes.bool,
	onClick: PropTypes.func,
}

export default RequestCard;
