import React from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

export const SubmitButton = (props) => {
	const {
		disabled,
		waiting,
	} = props;

	return (
		<div className="submit-button-wrapper d-flex align-items-center">
			<Button type="submit" size="lg" disabled={disabled}>
				Give Now
			</Button>

			{ waiting &&
				<i className="fa fa-spinner fa-spin fa-2x loading-icon" />
			}
		</div>
	);
}

SubmitButton.propTypes = {
	disabled: PropTypes.bool,
	waiting: PropTypes.bool,
}

export default SubmitButton;
