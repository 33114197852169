import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row'

import Label from './Label';
import ControlWrapper from './ControlWrapper';

export class FormGroup extends Component {
	get groupProps() {
		const {
			horizontal,
		} = this.props;

		let props = {};

		if ( horizontal ) {
			props.as = Row;
		}

		return props;
	}

	render() {
		const {
			groupProps,
			props: {
				horizontal,
				label,
				labelProps,
				controlColProps,
				children,
			},
		} = this;

		return (
			<Form.Group {...groupProps}>
				{ label &&
					<Label
						{...labelProps }
						label={ label }
						horizontal={ horizontal }
					/>
				}

				<ControlWrapper
					{...controlColProps}
					horizontal={horizontal}
				>
					{ children }
				</ControlWrapper>
			</Form.Group>
		);
	}
}

FormGroup.propTypes = {
	/** render horizontal layout */
	horizontal: PropTypes.bool,

	/** label text */
	label: PropTypes.string,

	/** to spread to react-bootstrap Form.Label */
	labelProps: PropTypes.object,

	/** to spread to react-bootstrap Col if horizontal */
	controlColProps: PropTypes.object,
}

FormGroup.defaultProps = {
	horizontal: true,
}

export default FormGroup;
