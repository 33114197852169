import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import StandardContainer from 'components/Lib/StandardContainer';

const TermsOfService = (props) => {
	return (
		<div className="page-terms-of-service content-wrapper">
			<StandardContainer>
				<Row>
					<Col xs={12}>
						<div className="terms-of-service-content">
							<h3 className="text-center title mb-4 mt-3">Terms of Service</h3>
							<p>Welcome to the Aunt Bertha, Inc. Donor Site (givetoaneighbor.org)– and thank you for visiting. We hope You enjoy the experience!</p>
							<p>THESE TERMS AND CONDITIONS (THE “TERMS” OR THIS “AGREEMENT”) ARE A LEGAL CONTRACT BETWEEN YOU (“YOU”) AND AUNT BERTHA, INC. (“AUNT BERTHA” OR “US” OR “OUR” OR “WE”). THE TERMS EXPLAIN HOW YOU ARE PERMITTED TO USE AND ACCESS THE SITE LOCATED AT THE URL: www.givetoaneighbor.org AS WELL AS ALL ASSOCIATED SITES LINKED TO www.auntbertha.com BY AUNT BERTHA, ITS SUBSIDIARIES AND AFFILIATED COMPANIES (COLLECTIVELY, THE “SITE” OR “DONOR SITE”). BY USING THIS SITE OR THE SERVICES, YOU ARE AGREEING TO ALL THE TERMS; IF YOU DO NOT AGREE WITH ANY OF THESE TERMS, DO NOT ACCESS OR OTHERWISE USE THIS SITE, ANY SERVICES AVAILABLE THROUGH THIS SITE OR ANY INFORMATION CONTAINED ON THIS SITE.BY ACCEPTING THESE TERMS, OR BY ACCESSING OR USING THE SITE OR SERVICES, OR AUTHORIZING AN END USER TO ACCESS OR USE THE SERVICES, YOU AGREE TO BE BOUND BY THESE TERMS.IF YOU ACCEPT OR AGREE TO THESE TERMS ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT AND WARRANT THAT YOU HAVE THE AUTHORITY TO BIND SUCH COMPANY OR OTHER LEGAL ENTITY TO THESE TERMS, AND IN SUCH EVENT, “YOU” AND “YOUR” WILL REFER AND APPLY TO SUCH COMPANY OR OTHER LEGAL ENTITY.</p>
							<p><strong>TERMS OF USE OF WEBSITE</strong><br/>
							We reserve the right, at our sole discretion, to change or modify parts of these Terms any time. When we do this, we will post the revised Terms on this page and will indicate the date of such revision.</p>
							<p>Your continued use of the Site after the date of any such changes constitutes your acceptance of the new Terms. To the extent allowed by law, the English version of these Terms is binding, and other translations are for convenience only. If you do not wish to accept the new Terms, you may discontinue your use of the Site.</p>
							<p><strong>Donation Description:</strong><br/>
							Aunt Bertha operates a website, Auntbertha.com (the “AuntBertha.com Site”), that connects users of the site with programs that provide for free or reduced cost services like medical care, food, job training, and more.  In addition, Auntbertha.com allows those users (referred to herein as the “Requester”) to submit online requests (“Requests”) for certain defined goods or services (the “Needed Item”) as specified on the AuntBertha.com Site to be paid for by various donors (“Donors”) through monetary donations ("Donations") by way of this website (the “Donor Site”), subject to these Terms. </p>
							<p>Aunt Bertha will use reasonable efforts to process Donations such that, upon receipt of the non-refundable Donation, the Needed Item is purchased by Aunt Bertha and delivered anonymously to address provided by the Requester (the “Fulfillment Process”).  The Donation amount listed on the Donor Site (the “Item Cost”) in connection with the Needed Item is equal to the cost to purchase and ship the Needed Item to the Requester plus a small fee to cover handling fee that is retained by Aunt Bertha (the “Processing Fee”).</p>
							<p>You acknowledge and agree that the Donor Site is a platform; Aunt Bertha is not a broker, financial institution, creditor or charity. All information and content provided by the Donor Site and/or Aunt Bertha relating to the Requests, other than the Needed Item and the Item Cost, is for information purposes only and neither the Donor Site nor Aunt Bertha guarantee the accuracy, completeness, timeliness or reliability of any such information or content. Before making any decisions regarding any Donations, or any information or content relating to the Requests, the Donor should consult Donor’s financial, legal, tax or other professional advisor as appropriate. Donor acknowledges that all Donations and any other access of the Donor Site is at Donor’s own risk.</p>
							<p>Aunt Bertha has no control over the conduct of, or any information provided by any Donor or any Requester or any other user and hereby disclaims all liability in this regard to the fullest extent permitted by applicable law. Aunt Bertha does not endorse any of the Requests, and makes no guarantee, express or implied, that any information provided through the Requester is accurate. Donor must make the final determination as to the value and appropriateness of contributing to the fulfillment of payment for any Requests.</p>
							<p>All Donations are at Donor’s own risk. Although Aunt Bertha performs the Fulfilment Process, Aunt Bertha does not represent or guarantee that the Donations will be used by the actual Requestor or in accordance with what is presented on the Donor Site for those Requests nor in accordance with applicable laws. Notwithstanding the foregoing, we take possible fraudulent activity and the misuse of funds raised very seriously. If you have reason to believe that the Donations are not being used for their stated purpose, please let us know on the Donor Site to alert our team of this potential issue and we will investigate.</p>
							<p><strong>Donation service fee:</strong><br/>
							As noted above, reasonable Processing Fee to cover processing costs made payable to Aunt Bertha will apply to all Donations made on the Donor Site.</p>
							<p><strong>Please note that by making a Donation, you hereby guarantee that you are at least 18 years of age.</strong></p>
							<p><strong>Passwords and Security:</strong><br/>
							You are responsible for maintaining the confidentiality of your password and account, if any, and are fully responsible for any and all activities that occur under your password or account. You agree to (a) immediately notify Aunt Bertha of any unauthorized use of your password or account or any other breach of security and (b) sign out from your account at the end of each session when accessing the Donor Site. Aunt Bertha will not be liable for any loss or damage arising from your failure to comply with this Section.</p>
							<p><strong>Modifications to AuntBertha.com Site:</strong><br/>
							Aunt Bertha reserves the right to modify, suspend or discontinue, temporarily or permanently, the  Donor Site (or any part thereof) at any time and for any reason, with or without notice, and without any liability to you or to any third party for any claims, damages, costs or losses resulting therefrom.</p>
							<p><strong>Public Content:</strong><br/>
							Some of your activity on and through the Donor Site is public, such as content you post publicly on the website. Additionally, user profile information, including your first and last name, public email address, organization, personal biography and other information you enter in connection with your user profile may be displayed to other users to facilitate user interaction within the Donor Site. Individuals reading such information may use or disclose it to other individuals or entities without our knowledge and without your knowledge and search engines may index that information. Please see our Privacy Policy for information on the ways that we may collect, use and store certain information about you and your use of the Donor Site.</p>
							<p><strong>Data Retention</strong><br/>
							You acknowledge that Aunt Bertha nor the Donor Site has any obligation to you to retain data relating to any Request. You acknowledge that Aunt Bertha and the Donor Site reserves the right to delete data or to terminate accounts or Request at any time and for any reason, with or without notice, and without any liability to you or to any third party for any claims, damages, costs or losses resulting therefrom.</p>
							<p><strong>Prohibited Conduct/ Usage</strong><br/>
							The following are examples of Content and/or use that is illegal or prohibited by Aunt Bertha and the Donor Site. This list is not exhaustive, and Aunt Bertha reserves the right to investigate anyone who, at their sole discretion, violates any of the terms or spirit of these Terms. We further reserve without limitation the right to remove the offending Content, suspend or terminate the account of such violators, stop payments to any such Requests, freeze or place a hold on Donations and report you to law enforcement authorities or otherwise take appropriate legal action including seeking restitution on behalf of ourselves and/or our users.</p>
							<p>With respect to all Donations you make through the Donor Site, you agree:</p>
							<ul>
								<li>not to make any Donations that you know or suspect to be erroneous, suspicious or fraudulent;</li>
								<li>not to use the Donor Site in or for the benefit of a country, organization, entity, or person embargoed or blocked by any government, including those on sanctions lists identified by the United States Office of Foreign Asset Control (OFAC);</li>
								<li>to maintain reasonable and standard security measures to protect any information transmitted and received through the Donor Site, including without limitation by adhering to any security procedures and controls required by Aunt Bertha from time to time;</li>
								<li>to maintain a copy of all electronic and other records related to Requests and Donations as necessary for Aunt Bertha to verify compliance with these Terms and make such records available upon our request. For clarity, the foregoing does not affect or limit your obligations to maintain documentation as required by applicable laws, rules, regulations or governmental authority; and</li>
								<li>at Aunt Bertha’s request, including without limitation in case of investigations by Aunt Bertha, a payment processing partner, or a regulatory or governmental authority, fully cooperate in the auditing of such records, investigation of the relevant circumstances and remedy of any uncovered violation or wrongdoing.</li>
							</ul>
							<p>Aunt Bertha reserves the right to refuse, condition, or suspend any Donations or other transactions that we believe in our sole discretion may violate these Terms or harm the interests of our users, business partners, the public, us, or that expose users or others to risks unacceptable to us. We may share any information related to your use of the Donor Site with the appropriate financial institution, regulatory authority, or law enforcement agency consistent with our legal obligations. This information may include information about you, your account, your Donations, and transactions made through or in connection with your use of the Donor Site.</p>
							<p><strong>Special Notice for International Use; Export Controls: </strong><br/>
							Software available in connection with the Donor Site and the transmission of applicable data, if any, may be subject to export controls and economic sanctions laws of the United States or other jurisdictions. No software may be downloaded from the Donor Site or otherwise exported or re-exported in violation of such export control and economic sanctions laws. Downloading or using the software is at your sole risk. Recognizing the global nature of the Internet, you agree to comply with all local rules and laws regarding your use of the Donor Site, including as it concerns online conduct and acceptable content.</p>
							<p><strong>Intellectual Property Rights</strong></p>
							<p><strong>Donor Site Content, Software and Trademarks:</strong><br/>
							You acknowledge and agree that the Donor Site contains content or features (" Donor Site Content") that are protected by copyright, patent, trademark, trade secret or other proprietary rights and laws. Except as expressly authorized by Aunt Bertha, you agree not to modify, copy, frame, scrape, rent, lease, loan, sell, distribute or create derivative works based on the  Donor Site Content, in whole or in part, except that the foregoing does not apply to your own User Content (as defined below) that you legally upload to the Donor Site. In connection with your use of the Donor Site, you will not engage in or use any data mining, robots, scraping or similar data gathering or extraction methods. If you are blocked by Aunt Bertha from accessing the Donor Site (including by blocking your IP address), you agree not to implement any measures to circumvent such blocking (e.g., by masking your IP address or using a proxy IP address). Any use of the Donor Site Content other than as specifically authorized herein is strictly prohibited. The technology and software underlying the Donor Site or distributed in connection therewith are the property of Aunt Bertha, our affiliates and our partners (the "Software"). You agree not to copy, modify, create a derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover any source code, sell, assign, sublicense, or otherwise transfer any right in the Software. Any rights not expressly granted herein are reserved by Aunt Bertha.
							The Aunt Bertha name and logos are trademarks and service marks of Aunt Bertha (collectively the "Aunt Bertha Trademarks"). Other company, product, and service names and logos used and displayed via the Donor Site may be trademarks or service marks of their respective owners, who may or may not endorse or be affiliated with or connected to Aunt Bertha. Nothing in these Terms or the Donor Site should be construed as granting, by implication, estoppel, or otherwise, any license or right to use any of Aunt Bertha Trademarks displayed on the Donor Site, without our prior written permission in each instance. All goodwill generated from the use of Aunt Bertha Trademarks will inure to our exclusive benefit.</p>
							<p><strong>Third-Party Material:</strong><br/>
							Under no circumstances will Aunt Bertha be liable in any way for any content or materials of any third parties or any User Content (defined below, including, but not limited to, for any errors or omissions in any content, or for any loss or damage of any kind incurred as a result of the use of any such content. You acknowledge that Aunt Bertha does not pre-screen content but that Aunt Bertha and its designees will have the right (but not the obligation) at their sole discretion to refuse, remove or allow any content that is available via the  Donor Site at any time and for any reason, with or without notice, and without any liability to you or to any third party for any claims, damages, costs or losses resulting therefrom.</p>
							<p><strong>User Content Transmitted through/on the Donor Site: </strong><br/>
							With respect to the content, photos, videos, images, trademarks, logos, brands or other materials you upload or post through the  Donor Site or share with other users or recipients (collectively, "User Content"), you represent and warrant that you own all right, title and interest in and to, or otherwise have all necessary rights and consents to (and to allow others to) fully exploit such User Content, including without limitation, as it concerns all copyrights, trademark rights and rights of publicity or privacy related thereto. By uploading, sharing, providing or otherwise making available any User Content, or any portion thereof, in connection with the Donor Site, you hereby grant and will grant Aunt Bertha and its affiliated companies and users a non-exclusive, worldwide, royalty-free, fully paid-up, transferable, sub-licensable, perpetual, irrevocable license to copy, display, upload, perform, distribute, store, modify and otherwise use your User Content in connection with the operation of the  Donor Site for the Donations including for advertising or marketing thereof, in any form, medium or technology now known or later developed. </p>
							<p>Without limiting the foregoing, if any User Content contains your name, image or likeness, you hereby release and hold harmless Aunt Bertha, its assigns and employees, from (i) all claims for invasion of privacy, publicity or libel, (ii) any liability or other claims by virtue of any blurring, distortion, alteration, optical illusion or other use or exploitation of your name, image or likeness, and (iii) any liability for claims made by you (or any successor to any claim you might bring) in connection with your User Content, name, image or likeness. You waive any right to inspect or approve any intermediary version(s) or finished version(s) of the results of the use of your User Content (including your name, image or likeness). Further, if any person (other than you) appears in your User Content, you represent and warrant that you have secured all necessary licenses, waivers and releases from such person(s) for the benefit of Aunt Bertha in a manner fully consistent with the licenses, waivers and releases set forth above. You further acknowledge that your participation in the  Donor Site and submission of User Content is voluntary and that you will not receive financial compensation of any type associated with the licenses, waivers and releases set forth herein and that the sole consideration for subject matter of this agreement is the opportunity to use the  Donor Site.</p>
							<p>We reserve the right to, but do not have any obligation to, (i) remove, edit or modify any  Donor Site Content or User Content, in our sole discretion, at any time, without notice to you and for any reason (including, but not limited to, upon receipt of claims or allegations from third parties or authorities relating to such  Donor Site Content or User Content, or if we are concerned that you may have violated these Terms), or for no reason at all and (ii) to remove or block any content from the  Donor Site.</p>
							<p>You acknowledge and agree that any questions, comments, suggestions, ideas, feedback or other information about the  Donor Site ("Submissions"), provided by you to Aunt Bertha are non-confidential and Aunt Bertha will be entitled to the unrestricted use and dissemination of these Submissions for any purpose, commercial or otherwise, without acknowledgment or compensation to you.</p>
							<p>You acknowledge and agree that Aunt Bertha may preserve content and may also disclose content if required to do so by law or in the good-faith belief that such preservation or disclosure is reasonably necessary to (a) comply with legal process, applicable laws or government requests; (b) enforce these Terms; (c) respond to claims that any content violates the rights of third parties; or (d) protect the rights, property, or personal safety of Aunt Bertha, its users or the public.</p>
							<p><strong>PRIVACY POLICY</strong><br/>
							We respect the information that you provide to us and want to be sure you fully understand exactly how We use that information. So, please review Our Privacy Policy (“Privacy Policy”), available at http://about.auntbertha.com/privacy, which explains everything.</p>
							<p><strong>Third Party Sites/Services</strong><br/>
							The Donor Site or third parties may provide or facilitate links, tools, widgets or other features that allow you to access other sites, services and resources provided by third parties (collectively, “Third Party Resources”). Aunt Bertha has no control over such Third Party Resources or any products, services or content made available through or by such Third Party Resources, or the business practices of the third parties providing such Third Party Resources, and Aunt Bertha is not responsible for and does not endorse such Third Party Resources or the products, services or content made available thereby. You acknowledge that Aunt Bertha is not responsible or liable for the content, functions, accuracy, legality, appropriateness or any other aspect of such Third Party Resources. You further acknowledge and agree that Aunt Bertha will not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any content, events, goods or services available on or through any such Third Party Resources. Any dealings you have with third parties found while using the  Donor Site are between you and the third party and may be subject to additional terms provided by the third party, which you agree to by using such Third Party Resources, and you agree that Aunt Bertha is not liable for any loss or claim that you may have against any such third party.</p>
							<p><strong>Indemnity and Release</strong><br/>
							You agree to release, indemnify on demand and hold Aunt Bertha and its affiliates and their officers, employees, directors and agents harmless from any and all losses, damages, expenses, including reasonable attorneys' fees, costs, awards, fines, damages, rights, claims, actions of any kind and injury (including death) arising out of or relating to your use of the Donor Site, any Donation, any User Content, any Requests, your connection to the Donor Site, your violation of these Terms or your violation of any rights of another. You agree that Aunt Bertha has the right to conduct its own defense of any claims at its own discretion and that you will indemnify Aunt Bertha for the costs of its defense (including, but not limited to legal fees.) </p>
							<p><strong>Disclaimer of Warranties</strong><br/>
							YOUR USE OF THE DONOR SITE IS AT YOUR SOLE RISK. THE DONOR SITE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. AUNT BERTHA AND ITS AFFILIATES EXPRESSLY DISCLAIM AND EXCLUDE, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, ALL WARRANTIES, CONDITIONS AND REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</p>
							<p>AUNT BERTHA AND ITS AFFILIATES MAKE NO GUARANTEE OR CONDITION THAT (I) THE DONOR SITE WILL MEET YOUR REQUIREMENTS, (II) THE DONOR SITE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE DONOR SITE WILL BE ACCURATE OR RELIABLE, OR (IV) THE DONATIONS REQUESTED OR DONATED BY YOU THROUGH THE DONOR SITE WILL MEET YOUR EXPECTATIONS.</p>
							<p><strong>Limitation of Liability </strong><br/>
							YOU EXPRESSLY UNDERSTAND AND AGREE THAT, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER AUNT BERTHA NOR ITS AFFILIATES WILL BE LIABLE FOR ANY (A) INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES, (B) DAMAGES FOR LOSS OF PROFITS, (C) DAMAGES FOR LOSS OF GOODWILL, (D) DAMAGES FOR LOSS OF USE, (E) LOSS OR CORRUPTION OF DATA, OR (F) OTHER INTANGIBLE LOSSES (EVEN IF AUNT BERTHA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, RESULTING FROM (I) THE USE OR THE INABILITY TO USE THE WEBSITE; (II) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (III) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE DONOR SITE; OR (IV) ANY OTHER MATTER RELATING TO THE WEBSITE. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL AUNT BERTHA'S TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES (INCLUDING CONTRACT, NEGLIGENCE, STATUTORY LIABILITY OR OTHERWISE) OR CAUSES OF ACTION EXCEED THE GREATER OF $100 OR THE TOTAL PROCESSING FEE PAID BY YOU TO AUNT BERTHA OVER THE TWELVE MONTH PERIOD IMMEDIATELY PRECEDING THE FILING OF THE CLAIM. IF APPLICABLE LAW LIMITS THE APPLICATION OF THIS SECTION, AUNT BERTHA’S (AND ITS AFFILIATES, LICENSORS AND AGENTS) LIABILITY WILL BE LIMITED TO THE MAXIMUM EXTENT PERMISSIBLE.</p>
							<p><strong>DISPUTES</strong><br/>
							Any dispute, controversy or claim arising under, out of or relating to this Agreement and any subsequent amendments of this Agreement, including, without limitation, its formation, validity, binding effect, interpretation, performance, breach or termination, as well as non-contractual claims, shall be submitted to mediation in accordance with the WIPO Mediation Rules. The place of mediation shall be Austin, Texas. The language to be used in the mediation shall be English. If, and to the extent that, any such dispute, controversy or claim has not been settled pursuant to the mediation within sixty (60) days of the commencement of the mediation, it shall, upon the filing of a Request for Arbitration by either party, be referred to and finally determined by arbitration in accordance with the WIPO Expedited Arbitration Rules. Alternatively, if, before the expiration of the said period of sixty (60) days, either party fails to participate or to continue to participate in the mediation, the dispute, controversy or claim shall, upon the filing of a Request for Arbitration by the other party, be referred to and finally determined by arbitration in accordance with the WIPO Expedited Arbitration Rules. The place of arbitration shall be Austin, Texas. The language to be used in the arbitral proceedings shall be English. The parties may apply to any court of competent jurisdiction for a temporary restraining order, preliminary injunction, or other interim or conservatory relief as necessary, without breach of this Section and without abridgment of the powers of the arbitrator.
							Notwithstanding the foregoing, nothing in these Terms will be deemed to waive, preclude or otherwise limit the right of either of us to (a) bring an individual action in small claims court; (b) pursue an enforcement action through the applicable US federal, state or local agency if that action is available; (c) seek injunctive relief in a court of law; or (d) file suit in a court of law to address an intellectual property infringement claim.</p>
							<p><strong>No Class Actions </strong><br/>
							YOU AND WE AGREE THAT EACH MAY BRING CLAIMS TO THE FULLEST EXTENT LEGALLY PERMISSIBLE AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF (CLAIMANT) OR CLASS MEMBER (GROUP LITIGANT) IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and we agree otherwise, the arbitrator may not consolidate more than one person’s claims and may not otherwise preside over any form of a representative or class (group litigation) proceeding.</p>
							<p>With respect to any disputes or claims not subject to Mediation or arbitration, as set forth above, you and Aunt Bertha agree to submit to the personal and exclusive jurisdiction of the state and federal courts located within Austin, Texas.</p>
							<p>We each agree to keep the arbitration proceedings, all information exchanged between us and any settlement offers confidential, unless otherwise required by law or requested by law enforcement or any court or governmental body. However, we may each disclose these matters, in confidence, to our respective accountants, auditors and insurance providers.</p>
							<p><strong>Termination</strong><br/>
							You agree that Aunt Bertha, at its sole discretion, may suspend or terminate your account (or any part thereof) or your access to the Donor Site and remove and discard any content or data at any time and for any reason, with or without notice, and without any liability to you or to any third party for any claims, damages, costs or losses resulting therefrom.</p>
							<p><strong>General Terms</strong><br/>
							These Terms constitute the entire agreement between you and Aunt Bertha and govern your use of the Donor Site, superseding any prior agreements between you and Aunt Bertha regarding the Donor Site. You also may be subject to additional terms and conditions that may apply when you use affiliate or third-party services, third-party content or third-party software.</p>
							<p>These Terms will be governed by the laws of the State of Texas without regard to its conflict of law provisions. The failure of Aunt Bertha to exercise or enforce any right or provision of these Terms will not constitute a waiver of such right or provision.</p>
							<p>If any provision of these Terms of Service is found by a court of competent jurisdiction to be (or are otherwise) invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties’ intentions as reflected in the provision, and the other provisions of these Terms remain in full force and effect. </p>
							<p>You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Donor Site, or these Terms must be filed within one (1) year after such claim or cause of action arose or be forever barred. </p>
							<p>A printed version of this agreement and of any notice given in electronic form will be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.</p>
							<p>You may not assign these Terms without the prior written consent of Aunt Bertha, but Aunt Bertha may assign or transfer these Terms, in whole or in part, without restriction. If we fail to enforce any of our rights, that does not result in a waiver of that right.</p>
							<p>The section titles in these Terms are for convenience only and have no legal or contractual effect. Notices to you may be made via either email or regular mail. The Donor Site may also provide notices to you of changes to these Terms or other matters by displaying notices or links to notices generally on the Donor Site.</p>
						</div>
					</Col>
				</Row>
			</StandardContainer>
		</div>
	);
}

export default TermsOfService;
