import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import SecondaryNavItem from './SecondaryNavItem';

export const SecondaryNavLink = (props) => {
	const {
		to,
		...restProps
	} = props;

	return (
		<Link to={to}>
			<SecondaryNavItem {...restProps} />
		</Link>
	);
}

SecondaryNavLink.propTypes = {
	...SecondaryNavItem.propTypes,

	to: PropTypes.string.isRequired,
}
export default SecondaryNavLink;
