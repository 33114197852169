import cssx from 'cssx';

function getColors(whitelabel) {
	if (
		! whitelabel ||
		! whitelabel.givingSiteDesign ||
		! whitelabel.givingSiteDesign.colors
	) {
		return {};
	}

	return whitelabel.givingSiteDesign.colors;
}

function nullCheck(color) {
	if ( ! color ) {
		return null;
	}

	return color;
}

export function setStyles(whitelabel) {
	const colors = getColors(whitelabel);

	let stylesheet = cssx('whitelabel');

	let mediaMdUp = stylesheet.add('@media (min-width: 768px)');
	let mediaSmDown = stylesheet.add('@media (max-width: 767.98px)');

	// GLOBAL
	stylesheet.add({
		'body': {
			color: nullCheck(colors.bodyText)
		}
	});
	stylesheet.add({
		'h1, h2, h3, h4, h5': {
			color: nullCheck(colors.headingText)
		},
		'.title-bar': {
			'background-color': nullCheck(colors.headingText)
		}
	});
	stylesheet.add({
		'a, .btn-link': {
			color: nullCheck(colors.bodyLinks)
		}
	});
	stylesheet.add({
		'.btn-primary': {
			'background-color': nullCheck(colors.buttons),
			'border-color': nullCheck(colors.buttons),
		},
		'.btn-primary:hover': {
			'background-color': nullCheck(colors.buttons),
			'border-color': nullCheck(colors.buttons),
		},
		'.btn-primary:not(:disabled):not(.disabled):active': {
			'background-color': nullCheck(colors.buttons),
			'border-color': nullCheck(colors.buttons),
		},
		'.btn-primary:focus': {
			'box-shadow': "none"
		}
	});

	// HEADER
	let logoNav = stylesheet.add('header .logo-nav');
	logoNav.update({
		'background-color': nullCheck(colors.headerBg),
		'color': nullCheck(colors.headerColor),
	});
	logoNav.descendant({ 'a': { 'color': nullCheck(colors.headerLinks) }})
	logoNav.descendant({ '.nav-item a': { 'color': nullCheck(colors.headerLinks) }})
	logoNav.descendant({ '.nav-item .btn-link': { 'color': nullCheck(colors.headerLinks) }})

	// HOW IT WORKS
	let howItWorks = stylesheet.add('header .how-it-works');
	howItWorks.update({
		'background-color': nullCheck(colors.howItWorksBg),
		'color': nullCheck(colors.howItWorksColor),
		'border-color': nullCheck(colors.howItWorksBorder),
	});
	howItWorks.descendant({ a: { color: nullCheck(colors.howItWorksLink) }});
	howItWorks.descendant({ h1: { color: nullCheck(colors.howItWorksColor) }});
	howItWorks.descendant({ h2: { color: nullCheck(colors.howItWorksColor) }});
	howItWorks.descendant({ h3: { color: nullCheck(colors.howItWorksColor) }});
	howItWorks.descendant({ h4: { color: nullCheck(colors.howItWorksColor) }});
	howItWorks.descendant({ h5: { color: nullCheck(colors.howItWorksColor) }});

	// MENU
	let menu = stylesheet.add('header .secondary-nav');
	menu.update({
		'background-color': nullCheck(colors.menuBg),
		'color': nullCheck(colors.menuColor),
	});
	menu.descendant({ 'a': {'color': nullCheck(colors.menuColor)} });
	menu.descendant({ '.program-title': {'color': nullCheck(colors.menuColor)} });
	menu.descendant({ '.btn-link': {'color': nullCheck(colors.menuColor)} });
	menu.descendant({
		'.program-selection-wrapper .secondary-nav-button .fa-stack .active': {
			'color': nullCheck(colors.menuActive)
		}
	});

	// PROGRAM
	stylesheet.add({
		'.page-program-give-gift .program-info .title': {
			'color': nullCheck(colors.programTitle),
		}
	});
	mediaMdUp.nested({
		'.page-program-give-gift': {
			'background': `linear-gradient(90deg, ${nullCheck(colors.programBgLeft)} 50%, ${nullCheck(colors.programBgRight)} 50%)`,
		}
	});
	mediaSmDown.nested({
		'.page-program-give-gift': {
			'background-color': nullCheck(colors.programBgRight),
		},
		'.page-program-give-gift .seeker-requests-wrapper': {
			'background-color': nullCheck(colors.programBgLeft),
		}
	});

	// REQUESTS
	stylesheet.add({
		'.request-card.active': {
			'background-color': nullCheck(colors.requestBg),
			'color': nullCheck(colors.requestColor),
			'border-color': nullCheck(colors.requestBorder),
		},
		'.request-card.active .title': {
			'border-color': nullCheck(colors.requestColor),
		}
	});

	// FULFILLED
	stylesheet.add({
		'.fulfilled-card': {
			'background-color': nullCheck(colors.fulfilledBg),
			'color': nullCheck(colors.fulfilledColor),
			'border-color': nullCheck(colors.fulfilledBorder),
		},
		'.fulfilled-card .title': {
			'border-color': nullCheck(colors.fulfilledColor),
		}
	});

	// THANK YOUS
	let thankYous = stylesheet.add('.thank-you-carousel');
	thankYous.descendant({
		'.background': {
			'background-color': nullCheck(colors.thankYouBg),
		},
		'.message': {
			'color': nullCheck(colors.thankYouColor),
		},
		'.location': {
			'color': nullCheck(colors.thankYouColor),
		}
	});
	mediaMdUp.nested({
		'.fulfilled-requests-ribbon': {
			'background': `linear-gradient(90deg, #FFFFFF 50%, ${nullCheck(colors.thankYouBg)} 50%)`,
		}
	});
	mediaSmDown.nested({
		'.fulfilled-requests-ribbon .thank-you-column': {
			'background-color': nullCheck(colors.thankYouBg),
		}
	});

	// FOOTER
	let footer = stylesheet.add('footer');
	footer.update({
		'background-color': nullCheck(colors.footerBg),
		'color': nullCheck(colors.footerColor),
	});
	footer.descendant({
		'a': {
			'color': nullCheck(colors.footerLink),
		},
		'a:hover': {
			'color': nullCheck(colors.footerLink),
		},
	})
}
