import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import TitleBar from 'components/Lib/TitleBar';
import StandardContainer from 'components/Lib/StandardContainer';
import ThankYouCarousel from 'components/Lib/ThankYouCarousel';

import FulfilledSlider from './FulfilledSlider';

class FulfilledRequestsRibbon extends Component {
	get givingSiteDesign() {
		const {
			givingSiteDesign,
		} = this.props.whitelabel;

		return (givingSiteDesign) ? givingSiteDesign : {};
	}

	get showFulfilledRequests() {
		const {
			showFulfilledRequests,
		} = this.givingSiteDesign;

		return (showFulfilledRequests) ? showFulfilledRequests : false;
	}

	get thankYouQuotes() {
		const {
			thankYouQuotes,
		} = this.givingSiteDesign;

		return (thankYouQuotes) ? thankYouQuotes : [];
	}

	get showThankYous() {
		const {
			thankYouQuotes,
		} = this;

		if ( ! thankYouQuotes || ! thankYouQuotes.length ) {
			return false;
		}

		return true;
	}

	render() {
		const {
			showFulfilledRequests,
			showThankYous,
			thankYouQuotes,
		} = this;

		if ( ! showFulfilledRequests ) {
			return null;
		}

		return (
			<div className="fulfilled-requests-ribbon">
				<TitleBar title="Fulfilled Requests" />
				<StandardContainer>
					<Row className="two-col-center-padding-md">
						<Col md={ (showThankYous) ? 6 : 12 } className="slider-column pt-5 pb-5">
							<FulfilledSlider />
						</Col>
						{ showThankYous &&
							<Col md={6} className="thank-you-column pt-md-4">
								<ThankYouCarousel quotes={ thankYouQuotes } />
							</Col>
						}
					</Row>
				</StandardContainer>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		whitelabel: state.whitelabel.active,
	}
}

FulfilledRequestsRibbon.propTypes = {
	whitelabel: PropTypes.shape({
		givingSiteDesign: PropTypes.shape({
			showFulfilledRequests: PropTypes.bool,
			thankYouQuotes: PropTypes.array,
		}),
	}).isRequired,
}

FulfilledRequestsRibbon = connect(mapStateToProps)(FulfilledRequestsRibbon);

export default FulfilledRequestsRibbon;
