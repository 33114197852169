import axios from 'axios';

import { getWhitelabelApiUrl } from 'utils/api';

const END_POINT = 'fulfilledRequests';
const ACTION_ROOT = 'fulfilledRequests';

const SET_FULFILLED_REQUESTS_LIST = `${ACTION_ROOT}/SET_FULFILLED_REQUESTS_LIST`;
const CLEAR_FULFILLED_REQUESTS = `${ACTION_ROOT}/CLEAR_FULFILLED_REQUESTS`;
const SET_FULFILLED_REQUESTS_LOADING = `${ACTION_ROOT}/SET_FULFILLED_REQUESTS_LOADING`;

const initialState = {
	list: [],
	loading: false,
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_FULFILLED_REQUESTS_LIST:
			return {
				...state,
				list: action.payload,
				loading: false,
			};
		case CLEAR_FULFILLED_REQUESTS:
			return { ...initialState };
		case SET_FULFILLED_REQUESTS_LOADING:
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
}

export function getFulfilledRequests({
	whitelabelKey,
	programCategory,
	zipcode,
} = {}) {
	return function(dispatch) {
		(dispatch)(setFulfilledRequestsLoading(true));

		axios.get( getWhitelabelApiUrl(whitelabelKey, END_POINT) )
		.then( (response) => {
			const filteredRequests = response.data.recent.filter( (request) => {
				if ( programCategory && request.category !== programCategory ) {
					return false;
				}

				// TODO: SMARTER ZIPCODE LOGIC
				if ( zipcode && request.location !== zipcode ) {
					return false;
				}

				return true;
			});

			dispatch({
				type: SET_FULFILLED_REQUESTS_LIST,
				payload: filteredRequests,
			});
		})
		.catch( (error) => {
			(dispatch)(setFulfilledRequestsLoading(false));
			console.error(error);
		});
	}
}

export function clearFulfilledRequests() {
	return function(dispatch) {
		dispatch({
			type: CLEAR_FULFILLED_REQUESTS,
		});
	}
}

export function setFulfilledRequestsLoading(payload) {
	return function(dispatch) {
		dispatch({
			payload,
			type: SET_FULFILLED_REQUESTS_LOADING,
		});
	}
}
