import { getPathProgram } from 'utils/routes';

// const END_POINT = 'programs';
const ACTION_ROOT = 'programs';

const SET_PROGRAMS_LIST = `${ACTION_ROOT}/SET_PROGRAMS_LIST`;
const SET_PROGRAM = `${ACTION_ROOT}/SET_PROGRAM`;
const CLEAR_PROGRAMS = `${ACTION_ROOT}/CLEAR_PROGRAMS`;

const initialState = {
	list: [],
	active: {},
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_PROGRAMS_LIST:
			const newPrograms = action.payload.map( (program) => {
				return {
					...program,
					linkTo: getPathProgram(program.slug),
				};
			});

			return {
				...state,
				list: newPrograms,
			};
		case SET_PROGRAM:
			return {
				...state,
				active: action.payload,
			}
		default:
			return state;
	}
}

export function setPrograms(programs=[]) {
	return function(dispatch) {
		dispatch({
			type: SET_PROGRAMS_LIST,
			payload: programs,
		});
	}
}

export function setProgram(payload={}) {
	return function(dispatch) {
		dispatch({
			payload,
			type: SET_PROGRAM,
		});
	}
}

export function clearActiveProgram() {
	return function(dispatch) {
		dispatch({
			type: SET_PROGRAM,
			payload: {},
		});
	}
}
