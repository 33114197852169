import React from 'react';

import SectionTitle from 'components/Lib/SectionTitle';

const TITLE = "Gift Requests";

export const DonationRequestsTitle = (props) => {
	return (
		<SectionTitle
			{...props}
			title={ TITLE }
		/>
	);
}

export default DonationRequestsTitle;
