import React, { Component } from 'react';

import FormControlInput from 'components/Lib/BootstrapReduxForm/FormControlInput';

import Name from './Name';
import StripeElements from './StripeElements';

export class PaymentInformation extends Component {
	render() {
		return (
			<div>
				<h3 className="header-rule">Your Information</h3>

				<Name />

				<FormControlInput
					name="emailAddress"
					label="Email"
					controlType="email"
				/>

				<FormControlInput
					name="addressZip"
					label="Zipcode"
				/>

				<StripeElements />

			</div>
		);
	}
}

export default PaymentInformation;
