import { REACT_APP_API_URL } from 'data/runtimeEnvVars';

export function getApiUrl(endpoint) {

	if ( ! REACT_APP_API_URL ) {
		throw new Error('Missing API URL');
	}

	if ( ! endpoint ) {
		throw new Error('Missing parameter');
	}

	return `${REACT_APP_API_URL}${endpoint}`;
}

export function getWhitelabelApiUrl(whitelabelKey, endpoint) {
	return getApiUrl(`whitelabel/${whitelabelKey}/${endpoint}`);
}
