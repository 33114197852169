import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import FormGroupLayout from './FormGroupLayout';
import Control from './Control';

export { FormGroupLayout };
export { Control };

/** returns ReduxForm.Field with defaults */
export const FormControlMoney = (props) => {
	return (
		<Field { ...props }/>
	);
}

FormControlMoney.propTypes = {
	/** Pass through model to value (ReduxForm.Field) */
	name: PropTypes.string.isRequired,  // Pass through model to value (field in redux-form 5)

	/** component to render, defaults to FormGroupLayout */
	component: PropTypes.func,
};

FormControlMoney.defaultProps = {
	component: FormGroupLayout,
};

export default FormControlMoney;
