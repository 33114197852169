export function getGiftTotalCents(donationRequests, selectedSeekerRequestKeys) {
	if ( ! selectedSeekerRequestKeys || ! Array.isArray(selectedSeekerRequestKeys) ) {
		return 0;
	}

	return selectedSeekerRequestKeys.reduce( (total, seekerRequestKey) => {
		const request = donationRequests.find( (request) => request.key === seekerRequestKey );

		if ( ! request || ! request.priceCents ) {
			return total;
		}

		return total + request.priceCents;
	}, 0);
}

export function getRoundUpTotalCents(giftTotalCents, giftCount) {
	if ( ! giftTotalCents || ! giftCount) {
		return 0;
	}

	if ( giftTotalCents < 5000 ) {
		return (Math.round( giftTotalCents / 500 ) + 1 ) * 500;
	}
	else if ( giftTotalCents < 7500 ) {
		return (Math.round( giftTotalCents / 1000 ) + 1 ) * 1000;
	}
	else if ( giftTotalCents < 20000 ) {
		return (Math.round( giftTotalCents / 2500 ) + 1) * 2500;
	}
	else if ( giftTotalCents < 50000 ) {
		return (Math.round( giftTotalCents / 5000 ) + 1) * 5000;
	}
	else {
		return (Math.round( giftTotalCents / 10000 ) + 1) * 10000;
	}
}
