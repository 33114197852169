import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { connectRouter } from 'connected-react-router';

import programs from './programs';
import donationRequests from './donationRequests';
import fulfilledRequests from './fulfilledRequests';
import stripe from './stripe';
import donation from './donation';
import mailingListSignup from './mailingListSignup';
import navigation from './navigation';
import whitelabel from './whitelabel';

const reducers = (history) => combineReducers({
	programs,
	donationRequests,
	fulfilledRequests,
	stripe,
	donation,
	mailingListSignup,
	navigation,
	form,
	whitelabel,
	router: connectRouter(history),
});

export default reducers;
