import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';

import { formatPenniesToMoney } from 'utils';

export class Control extends Component {
	constructor(props) {
		super(props);

		this.handleCheckClick = this.handleCheckClick.bind(this);
	}

	get checked() {
		const {
			value,
		} = this.props.input;

		return (value) ? true : false;
	}

	handleCheckClick() {
		const {
			checked,
			props: {
				input: {
					onChange,
					onBlur,
				}
			}
		} = this;

		onChange( ! checked );
		onBlur();
	}

	render() {
		const {
			checked,
			handleCheckClick,
			props: {
				afterRoundUpTotalInCents,
			},
		} = this;

		return (
			<div className="form-control-round-up text-sm-right">
				<Form.Check.Input
					checked={checked}
					onChange={ handleCheckClick }
				/>

				<Form.Check.Label onClick={ handleCheckClick }>
					Round up to {formatPenniesToMoney(afterRoundUpTotalInCents)} and help another family.
				</Form.Check.Label>
			</div>
		);
	}
}

Control.propTypes = {
	/** (usually from ReduxForm.Field) */
	input: PropTypes.shape({
		value: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.string,
		]),
		onBlur: PropTypes.func.isRequired,
		onChange: PropTypes.func.isRequired,
	}).isRequired,

	afterRoundUpTotalInCents: PropTypes.number.isRequired,
}

export default Control;
