export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function getCentsFromDollars( dollars ) {
	if( ! isNumeric(dollars) ) {
    return dollars;
  }

  return Math.floor(dollars * 100);
}

export function getDollarsFromCents( cents ) {
	if( ! isNumeric(cents) ) {
    return cents;
  }

  return (cents / 100);
}

export function defaultReadViewFormat( amount ) {
	if ( ! isNumeric( amount ) ) {
		return amount;
	}



	const decimals = 2;
	return '$' + (amount).toLocaleString('en', {minimumFractionDigits: decimals, maximumFractionDigits: decimals});
}
