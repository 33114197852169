import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';

import StandardContainer from 'components/Lib/StandardContainer';

const DEFAULT_LOGO_SRC = '/img/giveslogofinal.png';

class LogoNav extends Component {
	get logoSrc() {
		const {
			whitelabel,
		} = this.props;

		if ( ! whitelabel
			|| ! whitelabel.logo
			|| ! whitelabel.logo.url
		) {
			return DEFAULT_LOGO_SRC;
		}

		return whitelabel.logo.url;
	}

	render() {
		const {
			logoSrc,
			props: {
				onClickHowItWorks,
			},
		} = this;

		return (
			<Navbar expand="sm" collapseOnSelect className="logo-nav">
				<StandardContainer className="align-items-end">
					<Navbar.Brand>
						<Link className="logo-link" to="/">
							<img src={ logoSrc } alt="Give to a Neighbor" />
						</Link>
					</Navbar.Brand>
					<Navbar.Toggle aria-controls="top-header-nav" />
					<Navbar.Collapse id="top-header-nav">
						<Nav className="mr-auto" />
						<Nav className="justify-content-end">
							<Nav.Item>
								<Button variant="link" onClick={onClickHowItWorks}>
									How It Works
								</Button>
							</Nav.Item>
						</Nav>
					</Navbar.Collapse>
				</StandardContainer>
			</Navbar>
		);
	}
}

LogoNav.propTypes = {
	onClickHowItWorks: PropTypes.func.isRequired,
	whitelabel: PropTypes.shape({
		logo: PropTypes.shape({
			url: PropTypes.string,
		})
	})
}

export default LogoNav;
