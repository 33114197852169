import axios from 'axios';

import { getApiUrl } from 'utils/api';
import { setStyles } from 'utils/whitelabelStylesheet';

const END_POINT = 'whitelabel';
const ACTION_ROOT = 'whitelabel';

const SET_WHITELABEL = `${ACTION_ROOT}/SET_WHITELABEL`;

const initialState = {
	active: {},
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_WHITELABEL:
			return {
				...state,
				active: action.payload,
			}
		default:
			return state;
	}
}

export function getWhitelabel(domain, success) {
	return async function(dispatch) {
		const response = await axios.get( `${getApiUrl(END_POINT)}/${domain}` );

		const whitelabel = (response.data) ? response.data : {};

		setStyles(whitelabel);

		dispatch({
			type: SET_WHITELABEL,
			payload: whitelabel,
		});

		if (success) {
			success(response);
		}
	}
}
