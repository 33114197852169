import React, { Component } from 'react';
import { connect } from 'react-redux';
import Slider from 'rc-slider';
import { Flipper, Flipped } from 'react-flip-toolkit';

import * as actions from 'redux/modules/fulfilledRequests';
import { sortDate, ORDER_DESC } from 'utils/sort';

import FulfilledCard from 'components/Lib/FulfilledCard';

const MIN_SLIDER_STEPS = 1;
const MAX_SLIDER_STEPS = 10;

class FulfilledSlider extends Component {
	constructor(props) {
		super(props);

		this.state = {
			count: MIN_SLIDER_STEPS,
		}

		this.handleSliderChange = this.handleSliderChange.bind(this);
	}

	get sortedRequests() {
		const {
			requests,
		} = this.props;

		return sortDate(requests, "fullfilledOn", ORDER_DESC);
	}

	get maxRequests() {
		const {
			requests,
		} = this.props;

		return (requests.length < MAX_SLIDER_STEPS) ? requests.length : MAX_SLIDER_STEPS;
	}

	get sliderMarks() {
		const {
			maxRequests,
		} = this;

		let marks = {};

		for( let count = MIN_SLIDER_STEPS; count <= maxRequests; count++ ) {
			marks[count] = count;
		}

		return marks;
	}

	get selectedRequests() {
		const {
			sortedRequests,
			state: {
				count,
			},
		} = this;

		const selectedRequests = sortedRequests.slice(0, count);
		return selectedRequests.reverse();
	}

	componentDidMount() {
		const {
			getFulfilledRequests,
			whitelabel: {
				key: whitelabelKey,
			},
		} = this.props;

		if ( whitelabelKey ) {
			getFulfilledRequests({ whitelabelKey });
		}
	}

	handleSliderChange(count) {
		this.setState({ count });
	}

	render() {
		const {
			maxRequests,
			sliderMarks,
			selectedRequests,
			handleSliderChange,
			state: {
				count,
			},
		} = this;

		return (
			<div className="fulfilled-slider">
				<div className="form-group-slider d-flex">
					<label className="pr-3">
						Show More:
					</label>
					<div className="slider-wrapper flex-grow-1">
						<Slider
							min={MIN_SLIDER_STEPS}
							max={maxRequests}
							value={ count }
							marks={ sliderMarks }
							step={ null }
							onChange={ handleSliderChange }
						/>
					</div>
				</div>

				<div className="requests-list-wrapper">
					<Flipper flipKey={selectedRequests.map(req => req.key).join("")}>
						<ul className="list-unstyled">
							{ selectedRequests.map( (seekerRequest) => {
								const {
									key,
								} = seekerRequest;

								return (
									<Flipped key={key} flipId={key}>
										<li>
											<FulfilledCard
												{...seekerRequest}
											/>
										</li>
									</Flipped>
								);
							}) }
						</ul>
					</Flipper>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		requests: state.fulfilledRequests.list,
		whitelabel: state.whitelabel.active,
	}
}

FulfilledSlider = connect(mapStateToProps, actions)(FulfilledSlider);

export default FulfilledSlider;
