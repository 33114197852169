import { getValueFromKey } from '../utils';

export const ORDER_ASC = "ASC";
export const ORDER_DESC = "DESC";

export function compareValues(valueA, valueB) {
	if (valueA < valueB) {
		return -1;
	}
	if (valueA > valueB) {
		return 1;
	}

	return 0;
}

export function sortNumeric(data, fieldKey, order=ORDER_ASC) {
	let tmpData = data.map( (item) => item );

	tmpData.sort( (a, b) => {
		const valueA = ( getValueFromKey(a, fieldKey) ) ? getValueFromKey(a, fieldKey) : 0;
		const valueB = ( getValueFromKey(b, fieldKey) ) ? getValueFromKey(b, fieldKey) : 0;

		return compareValues( valueA, valueB );
	});

	if( order === ORDER_DESC ) {
		tmpData.reverse();
	}

	return tmpData
}

export function sortAlpha(data, fieldKey, order=ORDER_ASC) {
	let tmpData = data.map( (item) => item );

	tmpData.sort( (a, b) => {
		const valueA = ( getValueFromKey(a, fieldKey) ) ? getValueFromKey(a, fieldKey).toUpperCase() : "";
		const valueB = ( getValueFromKey(b, fieldKey) ) ? getValueFromKey(b, fieldKey).toUpperCase() : "";

		return compareValues( valueA, valueB );
	});

	if( order === ORDER_DESC ) {
		tmpData.reverse();
	}

	return tmpData
}

export function sortDate(data, fieldKey, order=ORDER_ASC) {
	let tmpData = data.map( (item) => item );

	tmpData.sort( (a, b) => {
		const valueA = ( getValueFromKey(a, fieldKey) ) ? new Date( getValueFromKey(a, fieldKey) ).getTime() : 0;
		const valueB = ( getValueFromKey(b, fieldKey) ) ? new Date( getValueFromKey(b, fieldKey) ).getTime() : 0;

		return compareValues( valueA, valueB );
	});

	if( order === ORDER_DESC ) {
		tmpData.reverse();
	}

	return tmpData
}
