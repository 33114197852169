import React, { Component } from 'react';
import PropTypes from 'prop-types';
import zipcodes from 'zipcodes';
import moment from 'moment';

export class RequestCard extends Component {
	get locationLabel() {
		const {
			neighborhood,
			zipcode,
			state,
		} = this.props;

		let label = "";

		if ( zipcode ) {
			const locationInfo = zipcodes.lookup(zipcode);

			if ( locationInfo ) {
				label = `${locationInfo.city}, ${locationInfo.state}`;
			}
			else {
				label = (state) ? state : zipcode;
			}
		}
		else {
			label = state;
		}

		if ( neighborhood ) {
			if ( label ) {
				label += ' - ';
			}

			label += neighborhood;
		}

		return label;
	}

	get dateLabel() {
		const {
			fullfilledOn,
		} = this.props;

		return `${moment(fullfilledOn).format("MMMM Do")}`;
	}

	render() {
		const {
			locationLabel,
			dateLabel,
			props: {
				details,
			},
		} = this;

		return (
			<div className="fulfilled-card">
				<div className="d-flex flex-row">
					<div className="details align-self-center">
						<div className="title">{ details }</div>
						<div className="d-flex flex-row justify-content-between">
							<div className="location">{ locationLabel }</div>
						</div>
					</div>

					<div className="date-wrapper">
						<div className="date-label">Fulfilled</div>
						<div className="date">{ dateLabel }</div>
					</div>
				</div>
			</div>
		);
	}
}

RequestCard.propTypes = {
	fullfilledOn: PropTypes.string.isRequired,
	details: PropTypes.string.isRequired,
	neighborhood: PropTypes.string,
	zipcode: PropTypes.string,
	state: PropTypes.string,
}

export default RequestCard;
