const ACTION_ROOT = 'navigation';

const SET_SHOW_HOW_IT_WORKS = `${ACTION_ROOT}/SET_SHOW_HOW_IT_WORKS`;

const initialState = {
	showHowItWorks: false,
}

export default function reducer( state = initialState, action ) {
	switch ( action.type ) {
		case SET_SHOW_HOW_IT_WORKS:
			return {
				...state,
				showHowItWorks: action.payload,
			}
		default:
			return state;
	}
}

export function setShowHowItWorks(value) {
	return {
		type: SET_SHOW_HOW_IT_WORKS,
		payload: value,
	};
}
