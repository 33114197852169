import React from 'react';

import LayoutXs from './LayoutXs';
import LayoutMd from './LayoutMd';

export const ProgramSelection = (props) => {
	return (
		<div className="program-selection-wrapper d-flex flex-row align-items-center">
			<div className="title">
				Help With:
			</div>

			<LayoutXs {...props} />
			<LayoutMd {...props} />
		</div>
	)
}

ProgramSelection.propTypes = {
	...LayoutMd.propTypes,
}

export default ProgramSelection;
