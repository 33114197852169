import React from 'react';
import PropTypes from 'prop-types';

export const SecondaryNavItem = (props) => {
	const {
		label,
		active,
	} = props;

	return (
		<div className="secondary-nav-button">
			<span className="fa-stack fa-2x">
				<i className="fas fa-circle fa-stack-2x"></i>
				{ active &&
					<i className="fas fa-check fa-stack-1x fa-inverse active" />
				}
			</span>

			<span className="label">{ label }</span>
		</div>
	);
}

SecondaryNavItem.propTypes = {
	label: PropTypes.string.isRequired,
	active: PropTypes.bool,
}

export default SecondaryNavItem;
