import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class ProgramTitle extends Component {
	get title() {
		const {
			title,
			label,
		} = this.props;

		if ( ! title ) {
			return `Help a Family With ${ label }`;
		}

		return title;
	}

	render() {
		const {
			title,
			props: {
				slug,
				className,
			},
		} = this;

		return (
			<div className={`program-info ${slug} ${className}`}>
				<div className="title-wrapper header-rule">
					<h1 className="title text-md-right">{ title }</h1>
				</div>
			</div>
		);
	}
}

ProgramTitle.propTypes = {
	slug: PropTypes.string,
	title: PropTypes.string,
}

export default ProgramTitle;
