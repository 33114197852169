import React from 'react';
import PropTypes from 'prop-types';
import Nav from 'react-bootstrap/Nav';

import SecondaryNavLink from './SecondaryNavLink';

export const LayoutMd = (props) => {
	const {
		programs,
		activeCategory,
	} = props;

	return (
		<div className="d-none d-md-block">
			<Nav>
				{ programs.map( (program) => {
					const {
						auntBerthaProgramIdentifier,
						category,
						label,
						linkTo,
					} = program;

					return (
						<Nav.Item key={auntBerthaProgramIdentifier}>
							<SecondaryNavLink
								label={ label }
								to={ linkTo }
								active={ category === activeCategory }
							/>
						</Nav.Item>
					);
				}) }
			</Nav>
		</div>
	);
}

LayoutMd.propTypes = {
	programs: PropTypes.arrayOf(PropTypes.shape({
		auntBerthaProgramIdentifier: PropTypes.string.isRequired,
		label: PropTypes.string.isRequired,
		linkTo: PropTypes.string.isRequired,
	})),
	activeCategory: PropTypes.string,
}

LayoutMd.defaultProps = {
	programs: [],
}

export default LayoutMd;
