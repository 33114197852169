import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Collapse from 'react-bootstrap/Collapse';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import StandardContainer from 'components/Lib/StandardContainer';

import DefaultContent from './DefaultContent';

class HowItWorks extends Component {
	get content() {
		const {
			whitelabel,
		} = this.props;

		if (
			! whitelabel ||
			! whitelabel.givingSiteDesign ||
			! whitelabel.givingSiteDesign.howItWorksHtml
		) {
			return <DefaultContent />
		}

		return <div dangerouslySetInnerHTML={{__html: whitelabel.givingSiteDesign.howItWorksHtml}} />
	}

	render() {
		const {
			content,
			props: {
				show,
			},
		} = this;

		return (
			<Collapse in={show}>
				<div className="how-it-works">
					<StandardContainer>
						<Row>
							<Col xs={12}>
								{ content }
							</Col>
						</Row>
					</StandardContainer>
				</div>
			</Collapse>
		);
	}
}

HowItWorks.propTypes = {
	show: PropTypes.bool,
	whitelabel: PropTypes.shape({
		givingSiteDesign: PropTypes.shape({
			howItWorksHtml: PropTypes.string,
		})
	}),
}

export default HowItWorks;
