import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import {
  StripeProvider,
  Elements
} from 'react-stripe-elements'

import { getDonationRequests, clearDonationRequests } from 'redux/modules/donationRequests';
import { setProgram } from 'redux/modules/programs';
import { setWaiting } from 'redux/modules/donation';

import { sortDate } from 'utils/sort';

import StandardContainer from 'components/Lib/StandardContainer';

import { getGiftTotalCents, getRoundUpTotalCents } from './utils';
import Form, { FORM_NAME } from './Form';
import Layout from './Layout';

const actions = {
	getDonationRequests,
	setProgram,
	setWaiting,
	clearDonationRequests,
};


export class ProgramGiveGift extends Component {
	constructor(props) {
		super(props);

		this.handleSetActiveProgram = this.handleSetActiveProgram.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}

	get sortedRequests() {
		const {
			donationRequests,
		} = this.props;

		return sortDonationRequestsByDate(donationRequests);
	}

	get giftCount() {
		const {
			selectedSeekerRequestKeys,
		} = this.props;

		if ( ! selectedSeekerRequestKeys ) {
			return 0;
		}

		return selectedSeekerRequestKeys.length;
	}

	get giftTotalCents() {
		const {
			donationRequests,
			selectedSeekerRequestKeys,
		} = this.props;

		return getGiftTotalCents(donationRequests, selectedSeekerRequestKeys);
	}

	get roundUpTotalCents() {
		const {
			giftCount,
			giftTotalCents,
		} = this;

		return getRoundUpTotalCents(giftTotalCents, giftCount);
	}

	get paymentTotalCents() {
		const {
			giftTotalCents,
			roundUpTotalCents,
			props: {
				openAmountInCents,
				selectedSeekerRequestKeys,
				roundUp,
			},
		} = this;

		if ( ! selectedSeekerRequestKeys || ! selectedSeekerRequestKeys.length ) {
			return (openAmountInCents) ? openAmountInCents : 0;
		}

		return (roundUp) ? roundUpTotalCents : giftTotalCents;
	}

	componentWillMount() {
		const {
			programSlug,
		} = this.props.match.params;

		this.handleSetActiveProgram(programSlug);
	}

	componentWillUpdate(nextProps) {
		const {
			programSlug: nextProgramSlug,
		} = nextProps.match.params;

		const {
			programSlug,
		} = this.props.match.params;

		if ( nextProgramSlug !== programSlug ) {
			this.handleSetActiveProgram(nextProgramSlug);
		}
	}

	handleSetActiveProgram(slug) {
		const {
			allPrograms,
			setProgram,
			getDonationRequests,
			clearDonationRequests,
			whitelabel: {
				key: whitelabelKey,
			},
		} = this.props;

		let program = allPrograms[0];

		if ( slug ) {
			program = allPrograms.find( (prg) => prg.slug === slug );
		}

		setProgram(program);

		if ( ! whitelabelKey || ! program || ! program.category ) {
			clearDonationRequests();
			return;
		}

		getDonationRequests({
			whitelabelKey,
			programCategory: program.category,
		});
	}

	handleFormSubmit(data) {
		const {
			paymentTotalCents,
			props: {
				setWaiting,
				activeProgram,
			},
		} = this;

		setWaiting(true);

		const newData = {
			...data,
			amountCents: paymentTotalCents,
			category: activeProgram.category,
		}

		if ( newData.openAmountInCents ) {
			delete newData.openAmountInCents;
		}

		// if ( newData.seekerRequestKeys && ! newData.seekerRequestKeys.length ) {
		// 	delete newData.seekerRequestKeys;
		// }

		return newData;
	}

	render() {
		const {
			sortedRequests,
			handleFormSubmit,
			roundUpTotalCents,
			paymentTotalCents,
			props: {
				activeProgram,
				allPrograms,
				donationRequestsLoading,
				selectedSeekerRequestKeys,
				initialValues,
				stripe,
				donationWaiting,
				errorMessage,
			},
		} = this;

		return (
			<div className="page-program-give-gift">
				<StripeProvider stripe={stripe}>
					<Elements>
						<Form
							onSubmit={ handleFormSubmit }
							initialValues={ initialValues }
						>
							<StandardContainer>
								<div className="content-wrapper">
									<Layout
										donationRequests={ sortedRequests }
										donationRequestsLoading={ donationRequestsLoading }
										selectedSeekerRequestKeys={ selectedSeekerRequestKeys }
										paymentTotalCents={ paymentTotalCents }
										activeProgram={ activeProgram }
										donationWaiting={ donationWaiting }
										errorMessage={ errorMessage }
										afterRoundUpTotalInCents={ roundUpTotalCents }
										multipleProgramsAvailable={ (allPrograms.length > 1) }
									/>
								</div>
							</StandardContainer>
						</Form>
					</Elements>
				</StripeProvider>
			</div>
		);
	}
}

function sortDonationRequestsByDate(donationRequests) {
	return sortDate(donationRequests, 'dateCreated')
}

const selector = formValueSelector(FORM_NAME);

function mapStateToProps(state) {
	const donationRequests = sortDonationRequestsByDate( state.donationRequests.list );
	const initSeekerRequestKeys = (donationRequests.length) ? [donationRequests[0].key] : [];

	return {
		donationRequests,
		donationRequestsLoading: state.donationRequests.loading,
		stripe: state.stripe.stripe,
		allPrograms: state.programs.list,
		activeProgram: state.programs.active,
		selectedSeekerRequestKeys: selector(state, 'seekerRequestKeys'),
		roundUp: selector(state, 'roundUp'),
		openAmountInCents: selector(state, 'openAmountInCents'),
		initialValues: {
			seekerRequestKeys: initSeekerRequestKeys,
		},
		donationWaiting: state.donation.waiting,
		errorMessage: state.donation.error,
		whitelabel: state.whitelabel.active,
	}
}

ProgramGiveGift = connect(mapStateToProps, actions)(ProgramGiveGift);

export default ProgramGiveGift;
