import React from 'react';

import FormGroup from '../FormGroup';
import Control from './Control';

export const FormGroupLayout = (props) => {
	return (
		<FormGroup {...props}>
			<Control {...props} />
		</FormGroup>
	);
}

FormGroupLayout.propTypes = {
	...FormGroup.propTypes,
}

export default FormGroupLayout;
